import React from 'react'
import UserIcon from './../../images/muh.jpg'
import './model4.css'
import { FaEnvelope, FaMapMarker, FaMobileAlt } from 'react-icons/fa';

const Model4 = () => {


    return (
        <div className="l-main bd-container">
            <div className="resume" id="area-cv">
                <div className="resume__left">
                    <section className="home" id="home">
                        <div className="home_containter section bd-grid">
                            <div className="home_data bd-grid">
                                <img src={UserIcon} alt="profile_pic" className="home_img center"/>
                                
                                <h1 className="home_title">MUHAMMAD <b>ESSA</b></h1>
                               
                            </div>
                            <div className="home_address bd-grid">
                                <span className="home_information">
                                    <FaMapMarker/>
                                     Rue 21, Bobo Dioulasso BF
                                </span>
                                <span className="home_information">
                                    <FaEnvelope/>
                                    stephane@gmail.com
                                </span>
                                <span className="home_information">
                                    <FaMobileAlt/>
                                    +266 7x-xx-xx-xx
                                </span>
                            </div>
                        </div>
                        
                       
                    </section>          
                    
                   
                    <section className="profile section" id="profile">
                        <h2 className="section-title">Profile</h2>
                        <p className="profile_description">
                          Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
                            Perspiciatis illo fugit officiis distinctio culpa officia 
                            totam atque exercitationem inventore repudiandae?  
                        </p>
                    </section>
                    
                   
                    <section className="education section" id="education">
                        <h2 className="section-title">Formations</h2>

                        <div className="education_container bd-grid">
                            <div className="education_content">
                                <div className="education_time">
                                    <span className="education_rounder"></span>
                                    <span className="education_line"></span>
                                </div>
                                <div className="education_data bd-grid">
                                    <h3 className="education_title">FACULTY OF SCIENCE</h3>
                                    <span className="education_studies">Leeds College Peshawar</span>
                                    <span className="education_year">2014 - 2016</span>
                                </div>
                            </div>

                            <div className="education_content">
                                <div className="education_time">
                                    <span className="education_rounder"></span>
                                </div>
                                <div className="education_data bd-grid">
                                    <h3 className="education_title">MATRICULATION</h3>
                                    <span className="education_studies">University Public School, Peshawar</span>
                                    <span className="education_year">2012 - 2014</span>
                                </div>
                            </div>
                        </div>
                    </section>
                    

                    <section className="skills section" id="skills">
                        <h2 className="section-title">Compétences</h2>

                        <div className="skills_content bd-grid">

                            <ul className="skills_data">
                                <li className="skills_name">
                                    <span className="skills_circle"></span> HTML 80%
                                </li>
                                <li className="skills_name">
                                    <span className="skills_circle"></span> CSS 70%
                                </li>
                                <li className="skills_name">
                                    <span className="skills_circle"></span> BOOTSTRAP 80%
                                </li>
                                <li className="skills_name">
                                    <span className="skills_circle"></span> JAVASCRIPT 60%
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
                

                <div className="resume__right">
                    <section className="experience section" id="experience">
                        <h2 className="section-title">Experience</h2>

                        <div className="experience_container bd-grid">

                            <div className="experience_content">
                                <div className="experience_time">
                                    <span className="experience_rounder"></span>
                                    <span className="experience_line"></span>
                                </div>
                                <div className="experience_data bd-grid">
                                    <h3 className="experience_title">WEB DEVELOPER</h3>
                                    <span className="experience_company">April 2021 - Till date | <a href="https://itartificer.com/">IT Artificer</a> </span>
                                    <p className="experience_description">
                                        After getting some experience, I hired as <b>Jr. Web Developer</b> at <a href="https://itartificer.com/">IT Artificer</a> in April 2021. ITA serving its Services all over the world especially in middle-east. ITA mainly focusing on delivering high quality software solutions which enable customers to achieve their critical IT objectives.
                                    </p>
                                </div>
                            </div>

                            <div className="experience_content">
                                <div className="experience_time">
                                    <span className="experience_rounder"></span>
                                </div>
                                <div className="experience_data bd-grid">
                                    <h3 className="experience_title">FRONTEND DEVELOPER</h3>
                                    <span className="experience_company">Nov 2020 - Dec 2020 | <a href="https://interns.pk/">Interns Pakistan</a></span>
                                    <p className="experience_description">
                                        As soon I graduated I selected as <b>Frontend Developer</b> at <a href="https://interns.pk/">Interns Pakistan</a> in November 2020. Interns Pakistan is a company who helping fresh graduates remotely by providing an internships. I awarded the best intern award by Interns Pakistan.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </section>


                    <section className="languages section">
                        <h2 className="section-title">Langues</h2>

                        <div className="languages_container">
                            <ul className="languages_content bd-grid">
                                    <li className="languages_name">
                                        <div className="data">
                                            <p className="semi-bold"> <span className="languages_circle"></span> English</p>
                                            <p>Très bien</p>
                                        </div>
                                    </li>
                                    <li className="languages_name">
                                        <div className="data">
                                            <p className="semi-bold"> <span className="languages_circle"></span> Dioula</p>
                                            <p>Très bien</p>
                                        </div>
                                    </li>
                                    <li className="languages_name">
                                        <div className="data">
                                            <p className="semi-bold"> <span className="languages_circle"></span> French</p>
                                            <p>Très bien</p>
                                        </div>
                                    </li>
                            </ul>

                        </div>

                    </section>
                    

                    <section className="interests section">
                        <h2 className="section-title">Centre d'interet</h2>

                        <div className="interests_container bd-grid">
                                <div className="interests_content">
                                    <span className="interests_name">Coding</span>
                                </div>
                                <div className="interests_content">
                                    <span className="interests_name">Coffee</span>
                                </div>
                                <div className="interests_content">
                                    <span className="interests_name">Traveling</span>
                                </div>
                                <div className="interests_content">
                                     <span className="interests_name">Photography</span>
                                </div>
                        </div>

                    </section>
                </div>
            </div>        
        </div> 
    )
}

Model4.displayName = 'model4';

export default Model4