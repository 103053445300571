import React from 'react'
import './../../model/cv/model1.css'
import { FaEnvelope, FaMapSigns, FaMobileAlt } from 'react-icons/fa';
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';

 const Model1 = ({data}) =>  {
    const [t, i18n] = useTranslation('global');
    const levelLabel = ['Intermediaire', 'bien', 'Très bien'];
    
    
    return (
        <div className="resume" id='min_size'>
            <div className="resume_left">

                {
                    data?.photo &&
                    <div className="resume_profile">
                        <img src={data?.photo} alt="profile_pic"/>
                    </div>
                }


                
                <div className="resume_content">
                    {/* La section Information personnelle */}
                    <div className="resume_item resume_info">
                        <div className="title">
                            <span className="bold">{(data?.info?.name) && `${data?.info?.name} ` }</span>
                            <span className="bold">{(data?.info?.firstname) && data?.info?.firstname }</span>
                        </div>
                        <ul>
                            {
                                (data?.info?.tel) && 
                                <li>
                                    <div className="icon">
                                        <FaMobileAlt/>
                                    </div>
                                    <div className="data">{data?.info?.tel}</div>
                                </li> 
                            }

                            {
                                (data?.info?.email) && 
                                <li>
                                    <div className="icon">
                                        <FaEnvelope/>
                                    </div>
                                    <div className="data">
                                        <a href={`mailto:${data?.info?.email}`} style={{color : 'white'}}>{data?.info?.email}</a>
                                    </div>
                                </li>
                            }

                            {
                                (data?.info?.postal_address) && 
                                <li>
                                    <div className="icon">
                                        <FaMapSigns/>
                                    </div>
                                    <div className="data">{data?.info?.postal_address}</div>
                                </li>
                            }

                        </ul>
                    </div>


                        {/* La section Competence */}

                        {
                            (data?.skill?.length > 0) &&
                            <div className="resume_item resume_skills">
                                <div className="title">
                                <p className="bold"> {(data?.skill?.length > 0) && t('cv.skill')} </p>
                                </div>
                                <ul>
                                    {data?.skill?.map((skill, i) => (
                                        <li key={i}>
                                            <div className="skill_name">{skill?.skill}</div>
                                            <div className="skill_progress">
                                                <span style={{width : `${(parseInt(skill?.level) * 30)}%`}}></span>
                                            </div>
                                        </li>
                                    ))}
                                </ul> 
                            </div>
                        }

                   


                        {/* La section Langues */}

                        {
                            (data?.langages?.length > 0) &&
                            <div className="resume_item resume_social">
                            <div className="title">
                                <p className="bold">{t('cv.language')}</p>
                            </div>
                                <ul>
                                    {data?.langages?.map((langage, i) => (
                                    <li key={i}>
                                        <div className="data">
                                            <p className="semi-bold">{langage?.langage}</p>
                                            <p>{levelLabel[(parseInt(langage?.level) - 1)]}</p>
                                        </div>
                                    </li>
                                    ))}
                                </ul> 
                            </div>
                        }
                   
                </div>
            </div>


            <div className="resume_right">
                {/* La section Profile */}
                {
                    (data?.profile) && 
                    <div className="resume_item resume_about">
                        <div className="title">
                            <p className="bold">{t('cv.profile')}</p>
                        </div>
                        <p>{data?.profile} </p>
                    </div>
                }


                
                {/* La section experience professionnelle */}

                    {
                        (data?.experiences?.length > 0) &&
                        <div className="resume_item resume_work">
                            <div className="title">
                                <p className="bold">{(data?.experiences) && t('cv.experience') }</p>
                            </div>
                            <ul>
                                {data?.experiences?.map((experience, i) => (
                                    <li key={i}>
                                        <div className="date">{dayjs(experience?.begin).format('DD/MM/YYYY')} au {dayjs(experience?.end).format('DD/MM/YYYY')}</div> 
                                        <div className="info">
                                            <p className="semi-bold">{experience?.job} ({experience?.etablissement} {experience?.city})</p> 
                                            <p>{experience?.description}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul> 
                         </div>
                    }

                

                {/* La section Formation */}

                    {
                        (data?.formations?.length > 0) &&
                            <div className="resume_item resume_education">
                                <div className="title">
                                    <p className="bold">{t('cv.education')}</p>
                                </div>
                                <ul>
                                    {data?.formations?.map((formation, i) => (
                                        <li key={i}>
                                            <div className="date">{dayjs(formation?.begin).format('DD/MM/YYYY')} au {dayjs(formation?.end).format('DD/MM/YYYY')}</div> 
                                            <div className="info">
                                                <p className="semi-bold">{formation?.formation} ({formation?.etablissement} {formation?.city})</p> 
                                                <p>{formation?.description}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul> 
                            </div>
                    }

                


                {/* La section Centre d'intéret */}
 
                    {
                        (data?.hobby?.length > 0) && 
                        <div className="resume_item resume_hobby">
                            <div className="title">
                                <p className="bold"> {(data?.hobby?.length > 0) &&  t('cv.hobby') }</p>
                            </div>
                            <ul>
                                {data?.hobby?.map((hobby, i) => (
                                        (<li key={i}>{hobby}</li>)
                                ))}
                            </ul> 
                        </div>
                    }  
                
            </div>
        </div>
    )
}

Model1.displayName = 'model1';

export default Model1;