import React, { useContext } from 'react'
import UserContext from '../context/UserContext'
import { Navigate, Outlet } from 'react-router-dom';

function CheckState() {

    const isAuth = useContext(UserContext);
    


    return !isAuth?.isAuth ? <Outlet></Outlet> : <Navigate to='/mon/profile'/>
}

export default CheckState