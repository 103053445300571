import React, { useEffect, useState } from 'react'
import Home from './pages/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CvModel from './pages/CvModel';
import './styles/app.css'
import Login from './pages/Login';
import CreateLdm from './pages/CreateLdm';
import Register from './pages/Register';
import axios from 'axios';
import UserContext from './context/UserContext';
import Profile from './pages/Profile';
import CheckStateUser from './components/CheckStateUser';
import CheckState from './components/CheckState';
import Admin from './components/Admin';
import AdminCv from './pages/AdminCv';
import AdminUser from './pages/AdminUser';
import AdminLdm from './pages/AdminLdm';
import CreateCv from './pages/CreateCv';

function App() {

  const [isAuth, setIsAuth] = useState({});
  const [permission, setPermission] = useState(false);


  axios.defaults.baseURL = 'https://serverapi.toittrouvé.com:6443'; 
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

  const CheckUser = async () =>{
    try {
      const res = await axios.get('/current_user');
      setIsAuth(true);
      setPermission(res.data?.permission);
    } catch (error) {
      setIsAuth(false);
    }
  }

  useEffect(() => {CheckUser()}, []);



  
  return (
    <UserContext.Provider value={{isAuth, setIsAuth, permission}}>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}  />
          <Route path='/cv-model' element={<CvModel/>} />
          <Route path='/ldm-model' element={<CreateLdm/>} />
          <Route path='/create_cv/:NomModel/:id' element={<CreateCv/>} />


          <Route path='/mon' element={<CheckStateUser/>}>
            <Route path='/mon/profile' element={<Profile/>} />
          </Route>

          <Route path='/page' element={<CheckState/>}>
            <Route path='/page/login' element={<Login/>} />
            <Route path='/page/register' element={<Register/>} />
          </Route>

          <Route path='/dashboard' element={<Admin/>}>
            <Route path='/dashboard/cv_list' element={<AdminCv/>} />
            <Route path='/dashboard/users_list' element={<AdminUser/>} />
            <Route path='/dashboard/ldm_list' element={<AdminLdm/>} />
          </Route>
          
          <Route path='*' element={<h1>Not found</h1>} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
