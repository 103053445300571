import React, { Suspense, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import './../styles/createLdm.css';
import './../styles/cvModel.css';
import { useNavigate, useParams } from 'react-router-dom';
import Experience from '../components/cv/Experience';
import Formation from '../components/cv/Formation';
import Hobby from '../components/cv/Hobby';
import Langue from '../components/cv/Langue';
import Skill from '../components/cv/Skill';
import Information from '../components/cv/Information';
import CreateCvHead from '../components/CreateCvHead';
import SelectPhoto from '../components/cv/SelectPhoto';
import ImportAll from '../helpers/ImportAll';
import GetCurrentCv from '../helpers/GetCurruentCv';



function CreateCv() {


    //les state suivant permettent la creation des champs pour les parties du cv
    const [inputFormations, setInputFormations] = useState([]);
    const [inputExperiences, setInputExperiences] = useState([]);
    const [inputSkills, setInputSkills] = useState([]);
    const [inputLangages, setInputLangages] = useState([]);
    const [inputHobby, setInputHobby] = useState([]);

    // ce state contiendra une sauvegarde de cv qui se trouvait dans la base de donnee
    const [cvBackup, setCvBackup] = useState({})


    /*les state suivant permettent la modification d'un modele de cv choisie a travers les information saisis
    * ils seront passe en tant que props au composant selectionner
    */
    const [info, setInfo] = useState({});
    const [photo, setPhoto] = useState(undefined);
    const [profile, setProfile] = useState('');
    
    const [listHobby, setListHobby] = useState([]);
    const [listFormation, setListFormation] = useState([]);
    const [listExperience, setListExperience] = useState([]);
    const [listSkill, setListSkill] = useState([]);
    const [listLangage, setListLangage] = useState([]);
    
    const [showProfileField, setShowProfileField] = useState(false);

    const [t, i18n] = useTranslation('global')

    const redirect = useNavigate();
    

    /*ici on recupere le parametre correspondant au nom du model de cv 
    * il permetra de choisi le model automatique parmis tous les model present
    */
    const {NomModel, id} = useParams();
    

    let eg = "Informaticien passionné avec plus de 8 ans d'expérience dans le développement de logiciels, la gestion de bases de données et l'administration système. Compétences approfondies dans la programmation orientée objet, les langages de script et les technologies de développement web. Solide background en conception, développement et déploiement de solutions informatiques robustes et évolutives.";


    /*lors du montage du composant cette instruction lira les anciennes valeur et les chargera si elles existent
    * on recupere l'information puis on charge les state qui sont passé au composant comme props
    */
    useEffect(()=>{
        //lors de la creation du composant on verifier si un identfiant de sauvegarde de cv est passer en parametre 
        //si oui on le recupere puis on recupere son contenu pour charger le state backup
        if (id !== 'none') {
            GetCurrentCv(`/cv/${id}`, setCvBackup, redirect);
        } else {
            let data = localStorage.getItem('cv_data');
            data = JSON.parse(data);
            setInfo({...data?.info});
            setProfile(data?.profile);
            setListFormation( data?.listFormation ? [...data?.listFormation] : [])
            setListExperience(data?.listExperience ? [...data?.listExperience] : [])
            setListSkill(data?.listSkill ? [...data?.listSkill] : [])
            setListLangage(data?.listLangage ? [...data?.listLangage] : [])
            setListHobby(data?.listHobby ? [...data?.listHobby] : [])
        }

        const saveImgUrl = localStorage.getItem('uploadedImg');
        setPhoto(saveImgUrl);
        
      }, []);


    //cette fonction appeler permettra d'importer automatiquement tous les composant react correspondant au modele de cv
    const components = ImportAll(require.context('./../modelUpdate/cv', false, /\.js$/))

    
    //cette fonction utilise les state pour ajouter du contenu dans les listes permettant des creer des champs
    // elle est appele lorsquon clique sur un button + et prends en argument le state associe a ce button a fin dajouter un element a la valeur du state
    const handleAddInput = (set) => {
        set( (oldValue)=> [...oldValue, { id: oldValue.length }]);
    };

    const handleInfo = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
    
        setInfo(values => ({...values, [name] : value}));
    
        const allData = {info, profile , listExperience, listFormation, listHobby,listLangage,listSkill};
        localStorage.setItem('cv_data', JSON.stringify(allData));
    }


    return (
        <div id='main_wrapper'>
            <CreateCvHead data={{info , profile, experience : listExperience, formation : listFormation, langage : listLangage, skill : listSkill, hobby : listHobby, nom_model : NomModel}}/>
            <div className='container_create_cv'>

                <div className='wrapper_editor'>

                    <Information handleInfo={handleInfo} info={info} />


                    <section className='area'>
                        <h3 className='title mix'>
                            <span>{t('cv.profile')}</span>
                            <button className='add' onClick={()=>{setShowProfileField(!showProfileField)}} > {showProfileField ? 'v' : '^'}</button>
                        </h3>
                        {
                            showProfileField && 
                            <div className='div_wrapper_input'>
                                <textarea 
                                    value={profile}
                                    rows={3} 
                                    className='body_textarea' 
                                    onChange={(e) =>{
                                        setProfile(e.target.value)
                                        const allData = {info, profile , listExperience, listFormation, listHobby,listLangage,listSkill};
                                        localStorage.setItem('cv_data', JSON.stringify(allData))
                                    }}></textarea>
                                <div>
                                    <span style={{backgroundColor:"blue", padding : "4px", color : "white"}} onClick={()=>{setProfile(eg)}}>Voir un ex</span>
                                </div>
                            </div>
                        }
                    </section>

                    <Formation inputFormations={inputFormations} setInputFormations={setInputFormations} handleAddInput={handleAddInput} setListFormation={setListFormation} listFormation={listFormation} />

                    <Experience inputExperiences={inputExperiences} setInputExperiences={setInputExperiences} handleAddInput={handleAddInput} listExperience={listExperience} setListExperience={setListExperience}/>

                    <Skill inputSkills={inputSkills} setInputSkills={setInputSkills} handleAddInput={handleAddInput} listSkill={listSkill} setListSkill={setListSkill}/>

                    <Langue inputLangages={inputLangages} setInputLangages={setInputLangages} handleAddInput={handleAddInput} listLangage={listLangage} setListLangage={setListLangage}/>

                    <Hobby inputHobby={inputHobby} setInputHobby={setInputHobby} handleAddInput={handleAddInput} listHobby={listHobby} setListHobby={setListHobby}/>
                    <SelectPhoto setSrcPhoto={setPhoto}/>


                </div>

                
                <div className='wrapper_result'>
                    <Suspense fallback={<div>Chargement...</div>}>
                        {components.map((Component, index) => {

                            if (Component.displayName === NomModel) {
                                return (
                                    <div className='div_cv' id='my_cv' key={index}>
                                        <Component   data={id !== 'none' ? cvBackup : {info, profile, hobby : listHobby, formations : listFormation, experiences : listExperience, skill : listSkill, langages : listLangage, photo}}/>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </Suspense>
                </div>


            </div>
        </div>
    )
}

export default CreateCv