import axios from 'axios';
import React, { useContext, useState } from 'react'
import UserContext from '../context/UserContext';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next'
import { FaEye } from 'react-icons/fa';

function ChangePassword() {
    const [visible, setVisible] = useState(false);
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [repeatNewPassword, setRepeatNewPassword] = useState();
    const [error, setError] = useState('');
    const [t, i18n] = useTranslation('global');
    const {setIsAuth} = useContext(UserContext);

    const handleSubmit = async () => {

        if (!oldPassword) {
            setError('Veillez saisir votre ancien mot de passe');
        }
        else if (!newPassword) {
            setError('Veillez saisir votre nouveau mot de passe');
        }
        else if (!repeatNewPassword) {
            setError('Veillez repeter votre nouveau mot de passe');
        }
        else if (repeatNewPassword != newPassword) {
            setError('Les deux nouveau mot de passe doivent etre identique');
        }
        else if (newPassword.length < 8) {
            setError('Le mot de passe doit contenir au moins 8 caractères');
        }
        else{
            try {
                const res = await axios.put('/change_password', {oldPassword, newPassword, repeatNewPassword});
                Swal.fire({icon : 'success', text : res.data?.message})
                localStorage.removeItem('token');
                setIsAuth(false);
            } catch (error) {
                if (error?.response) {
                    setError(error?.response.data.message);
                }
                else{
                    setError('Desolé une erreur de survenue');
                }
            }
        }
    }


    return (
        <div>
            <h2>{t("login.change_pwd")}</h2>
            <form method='post' >
                <small className='error'>{error}</small>
                <div className='div_input_change_passe'>
                    <p>
                        <label htmlFor='oldPassword'>{t("login.old_pwd")}</label>
                    </p>
                    <input type={visible ? 'text' : 'password'} name='oldPassword' id='oldPassword' onChange={(e) => {setOldPassword(e.target.value)}}/>
                </div>

                <div className='div_input_change_passe'>
                    <p>
                        <label htmlFor='newPassword'>{t("login.new_pwd")}</label>
                    </p>
                    <input type={visible ? 'text' : 'password'} name='newPassword' id='newPassword' onChange={(e) => {setNewPassword(e.target.value)}}/>
                </div>

                <div className='div_input_change_passe'>
                    <p>
                        <label htmlFor='repeatNewPassword'>{t("login.r_new_pwd")}</label>
                    </p>
                    <input type={visible ? 'text' : 'password'} name='repeatNewPassword' id='repeatNewPassword' onChange={(e) => {setRepeatNewPassword(e.target.value)}}/>
                </div>

                <div className='div_input_change_passe'>
                    <p>
                        <input type='checkbox' id='check' onChange={()=>{setVisible(!visible)}} checked={visible}/>
                        <label htmlFor='check' className='check_lab'><FaEye size={20}/></label>
                    </p>
                </div>

                <div className='div_input_change_passe'>
                    <button type='button' onClick={handleSubmit}>{t("login.validate")}</button>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword