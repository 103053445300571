import React, { useContext, useEffect, useState } from 'react'
import userImg from './../images/user.png'
import './../styles/profile.css'
import UserContext from '../context/UserContext'
import ChangePassword from '../components/ChangePassword';
import { useNavigate } from 'react-router-dom';
import GetData from '../helpers/GetData';
import {FaTrash, FaEye} from 'react-icons/fa';
import DeleteDoc from '../helpers/DeleteDoc';
import Dayjs from "dayjs";
import {useTranslation} from 'react-i18next'

function Profile() {

  const [user, setUser] = useState();
  const [cv, setCv] = useState([]);
  const [ldm, setLdm] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const {t, i18n} = useTranslation('global');
  const {setIsAuth} = useContext(UserContext);
  const redirect = useNavigate();
  


  const Logout = () => {
    localStorage.removeItem('token');
    setIsAuth(false);
  }


  useEffect(() => {
    GetData('/my_profile', setUser);
    GetData('/my_cv', setCv);
    GetData('/my_ldm', setLdm);
  }, []);


  return (
      <div>
        <div className='profile_container'>

          <div className='sub_container_1'>
              <div className='wrapper_img'>
                <img src={userImg} alt='profil'/>
              </div>
              <div>
                {user?.permission && <button className='btn_dash' onClick={()=>{setShowLink(!showLink)}} >Dashboard</button>}
              </div>
          </div>

          <div className='sub_container_2'>
            <div>
            <h1 className='info'>{user?.name} {user?.firstname}</h1>

            <div className='head'>
              <strong>{t("login.about_you")}</strong>
            </div>

            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Identifiant</td>
                    <td>{user?._id}</td>
                  </tr>
                  <tr>
                    <td>{t("login.name")}</td>
                    <td>{user?.name}</td>
                  </tr>
                  <tr>
                    <td>{t("login.firstname")}</td>
                    <td>{user?.firstname}</td>
                  </tr>
                  <tr>
                    <td>{t("login.email")}</td>
                    <td>{user?.email}</td>
                  </tr>
                  <tr>
                    <td>{t("login.created_at")} </td>
                    <td>{Dayjs(user?.created_at).format('DD-MM-YYYY  HH:mm')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
           <div className='div_logout'>
            <button className='btn_logout' onClick={Logout}>{t("login.logout")}</button>
            <button className='btn_home' onClick={()=>{redirect('/')}}>{t("header.home")}</button>
            
           </div>

           {
            (user?.permission && showLink) &&
            <div className='wrapper_btn_link'>
              <div>
                <button className='btn_link' onClick={()=>{redirect('/dashboard/users_list')}}>utilisateurs</button>
                <button className='btn_link' onClick={()=>{redirect('/dashboard/cv_list')}}>les Cv</button>
                <button className='btn_link' onClick={()=>{redirect('/dashboard/ldm_list')}}>les Ldm</button>
              </div>
            </div>
           }
           </div>
          </div>

          <div className='sub_container_3'>
             <ChangePassword/>
          </div>
        </div>

        <div className='my_document'>

          <div className='all_my_cv'>
            <h2>{t("login.my_cv")}</h2>
            <div>
              {
                cv?.map((item,i) => (
                  <div className='id_cv' key={i} >
                    <p>{item?._id}</p>
                    <div>
                      <span className='wrapper_icon_eye' onClick={()=>{redirect(`/create_cv/${item?.nom_model}/${item?._id}`)}}>
                        <FaEye/>
                      </span>
                      <span className='wrapper_icon_trash' onClick={()=>{DeleteDoc(`/delete_cv/${item?._id}`, setCv, item?._id)}}>
                        <FaTrash/>
                      </span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className='all_my_ldm'>
            <h2>{t("login.my_letter")}</h2>
            <div>
              {
                ldm?.map((item,i) => (
                  <div className='id_ldm' key={i} >
                    <p>{item?._id}</p>
                    <div>
                      <span className='wrapper_icon_eye' onClick={()=>{redirect('/ldm-model')}}>
                        <FaEye/>
                      </span>
                      <span className='wrapper_icon_trash' onClick={()=>{DeleteDoc(`/delete_ldm/${item?._id}`, setLdm, item?._id)}}>
                        <FaTrash/>
                      </span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
  )
}

export default Profile