import React from 'react'
import { Link } from 'react-router-dom'
import './../styles/home.css'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Home() {

    const [t,i18n] = useTranslation('global');
    const redirect = useNavigate();

    return (
        <main className='home'>
        
        <Header/>
            <section className="section__container header__container">
                <div className="header__image">
                    <img className='home-img' src="assets/images/header.png" alt="header" />
                </div>
                <div className="header__content">
                    <h2>{t("home.creator")}</h2>
                    <h1>{t("home.only")}</h1>
                    <p>{t("home.use")}</p>
                    <div className="header__btns">
                        <Link to="/cv-model" className = "btn btn-primary text-uppercase">{t("home.create_btn")} </Link>
                        <Link to="/ldm-model" className = "btn btn-secondary text-uppercase">{t("home.create_btn_ldm")}</Link>
                    </div>
                </div>
            </section>

            <section className="section-one">
                <div className="container">
                    <div className = "section-one-content">
                        <div className="section-one-l">
                            <img  className='home-img'src = "assets/images/1.png" />
                        </div>
                        <div className = "section-one-r text-center">
                            <h2 className = "lg-title">{t("home.sect1h2")}</h2>
                            <p className = "text">{t("home.sect1p")}</p>
                            <div className = "btn-group">
                                <Link to="/cv-model" className="btn btn-primary text-uppercase">{t("home.create_btn")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{marginBottom : '15rem'}}  className="section__container talent__container">
                <h2 className="section__header">{t("home.how")}</h2>
                <div className="talent__grid">
                    <div className="talent__card">
                        <span><i className="ri-user-line"></i></span>
                        <h4>{t("home.register")}</h4>
                        <p>{t("home.register_detail")}</p>
                    </div>
                    <div className="talent__card">
                        <span><i className="ri-landscape-line"></i></span>
                        <h4>{t("home.choose_model")}</h4>
                        <p> {t("home.choose_model_detail")}</p>
                    </div>
                    <div className="talent__card">
                        <span><i className="ri-survey-fill"></i></span>
                        <h4>{t("home.form")}</h4>
                        <p>{t("home.form_detail")}</p>
                    </div>
                    <div className="talent__card">
                        <span><i className="ri-download-line"></i></span>
                        <h4>{t("home.download")}</h4>
                        <p>{t("home.download_detail")}</p>
                    </div>
                </div>
            </section>


            <section style={{marginBottom : '20rem'}} className="section__container register__container">
                <div className="register__grid">
                    <div className="register__content">
                        <h4>{t("home.generate")}</h4>
                        <div className="register__btn">
                            <button className="mbtn" onClick={()=>{redirect('/cv-model')}}>{t("home.start")}</button>
                        </div>
                    </div>
                    <div className="register__image">
                        <img className='home-img' src="assets/images/register.png" alt="register" />
                    </div>
                </div>
            </section>



            <div className="section-two bg-bright">
                <div className="container">
                    <div className="section-two-content">
                        <div className = "section-items">
                            <div className = "section-item">
                                <div className = "section-item-icon">
                                    <img className='home-img' src = "assets/images/feature-1-edf4481d69166ac81917d1e40e6597c8d61aa970ad44367ce78049bf830fbda5.svg" alt = "" />
                                </div>
                                <h5 className = "section-item-title">{t("home.sect2_1_h5")}</h5>
                                <p className = "text">{t("home.sect2_1_p")}</p>
                            </div>

                            <div className = "section-item">
                                <div className = "section-item-icon">
                                    <img className='home-img' src = "assets/images/feature-2-a7a471bd973c02a55d1b3f8aff578cd3c9a4c5ac4fc74423d94ecc04aef3492b.svg" alt = "" />
                                </div>
                                <h5 className = "section-item-title">{t("home.sect2_2_h5")}</h5>
                                <p className = "text">{t("home.sect2_2_p")}</p>
                            </div>

                            <div className="section-item">
                                <div className="section-item-icon">
                                    <img  className='home-img' src="assets/images/feature-3-4e87a82f83e260488c36f8105e26f439fdc3ee5009372bb5e12d9421f32eabdd.svg" alt = "" />
                                </div>
                                <h5 className="section-item-title">{t("home.sect2_3_h5")}</h5>
                                <p className="text">{t("home.sect2_3_p")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <Footer/>
        </main>
  )
}

export default Home