import React from 'react'
import dayjs from 'dayjs';
import './../styles/createLdm.css'
import { useTranslation } from 'react-i18next';

function Ldm({info, object, content}) {

    const [t, i18n] = useTranslation('global');
    
    return (
        <div className='ldm' id='ldm'>

            <div className='personal_info area'>
              <div>
                <p>
                  {info?.name && <strong>{info?.name } </strong>}
                  {info?.firstname && <strong>{info?.firstname }</strong>}
                </p>
                {info?.postal_address && <p> {info?.postal_address }</p>}
                {info?.email && <p> <a href={`mailto:info?.email`} style={{color : 'blue'}}>{info?.email }</a> </p>}
                {info?.tel && <p>{info?.tel }</p>}
              </div>

              <div>
                <h2> {info?.country && `${info?.country }`}</h2>
                <p>{info?.devise && `${info?.devise }`}</p>
              </div>
            </div>

            <div className='entreprise_info area'>
              <h3>{info?.receiver && `${info?.receiver } `}</h3>
              <p>{info?.receiver_address && `${info?.receiver_address } `}</p>
            </div>

            <div className='date_place area'>
              <p>{info?.city && `A ${info?.city } `}  {info?.day && `, le ${dayjs(info?.day).format('DD-MM-YYYY') } `} </p>
            </div>


            {
              object && 
              <div className='object area'>
                <h3>{t('ldm.obj')} : {object }</h3>
              </div>
            }


            <div>
              
                {
                  content?.introduction && 
                  <div className='ldm_introduction area'> 
                    <p>{content?.introduction }</p> 
                  </div>
                }
              
                {
                  content?.motivation &&
                  <div className='ldm_motivation area'> 
                    <p>{content?.motivation }</p> 
                  </div>
                }
              

              
                {
                  content?.compatibility &&
                   <div className='ldm_adequation area'>
                     <p>{content?.compatibility }</p> 
                   </div>
                }
              

              
                {
                  content?.skill && 
                  <div className='ldm_competence area'> 
                    <p>{content?.skill }</p>
                  </div>
                }
              

              
                {
                  content?.experience && 
                  <div className='ldm_experience area'> 
                    <p>{content?.experience }</p> 
                  </div>
                }
              

              
                {
                  content?.conclusion && 
                  <div className='ldm_conclusion area'> 
                    <p>{content?.conclusion }</p> 
                  </div>
                }
              

              
                {
                  content?.greeting && 
                  <div className='ldm_greeting area'>
                    <p>{content?.greeting }</p> 
                  </div>
                }
              
            </div>

          </div>
    )
}

export default Ldm