import axios from 'axios';
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaArrowLeft, FaDownload } from 'react-icons/fa'
import UserContext from '../context/UserContext';
import { useTranslation } from "react-i18next";



function CreateCvHead({data}) {
    const user = useContext(UserContext);
    const [t, i18n] = useTranslation('global');
    const redirect = useNavigate();


    const handleDownload = () =>{

        Swal.fire({
            title: 'Téléchargement',
            text: 'Voulez-vous vraiment télécharger ce document ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            
            if (result.isConfirmed) {
                if (user.isAuth) {
                    const captureElement = document.getElementById('min_size');
                    captureElement.setAttribute('style', 'width:210mm !important;height:297mm !important')
            
                    html2canvas(captureElement, {scale : 9}).then(canvas => {
                        const imgData = canvas.toDataURL('image/jpeg', 1.0);
                        const doc = new jsPDF('p','mm','a4');
                        doc.addImage(imgData,'JPEG', 0, 0, 210, 297);
                        doc.save('edoc_cv.pdf');
                        
                    });
                }
                else{
                    Swal.fire({icon : 'warning', title : 'Alerte', text : 'Veillez vous connecté a fin de pouvoir télécharger ce document'});
                }
            }
        });


    }

    const postData = () =>{

        Swal.fire({
            title: 'Sauvegarde',
            text: 'Voulez-vous vraiment Sauvegarder ce document chez nous ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.post('/create_new_cv', data);
                    Swal.fire({icon : 'success', title : 'Enrégistrer', text : 'Le contenu de votre cv a été enregistré dans la base de donnée'})
                } catch (error) {
                    if (error?.response) {
                        Swal.fire({icon : 'warning', title : 'Désolé', text : error?.response?.data?.message})
                    } else {
                        Swal.fire({icon : 'error', title : 'Erreur', text : 'Nous avons rencontré une erreur veillez reessayé plus tard'});
                    }
                }
            } else {
            }
        });

    }
    
    return (
        <div className='create_head' >
            <button type='button' onClick={()=>{redirect(-1)}} > <FaArrowLeft/> Back</button>
            <div>
                <button type='button' onClick={postData} className='btn-position' >{t('ldm.save')}</button>
                <button type='button' onClick={handleDownload} > <FaDownload/> {t('ldm.download')}</button>
            </div>
        </div>
    )
}

export default CreateCvHead