import React from 'react'
import './model3.css'
import UserIcon from './../../images/user.png'
import { FaEnvelope, FaMapMarker, FaMobileAlt } from 'react-icons/fa';

const  Model3 = () => {
    return (
            <div className="model3_container">
                <div className="left_Side">
                    <div className="profileText">
                        <div className="imgBx">
                            <img className="photo" src={UserIcon} alt="profile_pic"/>
                           
                        </div>
                        <br/>
                        <h2>
                            <span >KABORE</span>
                            <span>STEPHANE</span>
                        </h2>
                    </div>

                    <div className="contactInfo">
                        <h3 className="title">Contact Info</h3>
                        <ul>
                            <li>
                                <span className="icon"><FaMobileAlt/></span>
                                <span className="text">+266 7x-xx-xx-xx</span>
                            </li>
                            <li>
                                <span className="icon"><FaEnvelope/></span>
                                <span className="text">step@gmail.com</span>
                            </li>
                            <li>
                                <span className="icon"><FaMapMarker/></span>
                                <span className="text">Rue 21, Bobo Dioulasso BF</span>
                            </li>
                        </ul>
                    </div>

                    <div className="contactInfo education">
                        <h3 className="title">Formation</h3>

                        <ul>
                            <li>
                                <h5>12/04/2010 - 12/04/2013</h5>
                                <h4>Master Degree in Computer Science (University Name)</h4>
                                <p></p>
                            </li>
                            <li>
                                <h5>12/04/2007 - 12/04/2013</h5>
                                <h4>Bachelor Degree Computer Science (University Name)</h4>
                                <p></p>
                            </li>
                            <li>
                                <h5>12/04/1997 - 12/04/2007</h5>
                                <h4>Matriculation (University Name) </h4>
                                <p></p>
                            </li>
                        </ul>
                    </div>

                    <div className="contactInfo language">
                        <h3 className="title">Languages</h3>

                        <ul>
                            <li>
                                <span className="text">English</span>
                                <span className="percent">
                                    <div className="englishw50"></div>
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <span className=" text">Russian</span>
                                <span className="percent">
                                    <div className="russianw90"></div>
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <span className="text">French</span>
                                <span className="percent">
                                    <div className="frenchw30"></div>
                                </span>
                            </li>
                        </ul>

                    </div>
                </div>


                <div className="right_Side">
                    <div className="about">
                        <h2 className="title2">Profile</h2>
                        <p>
                        <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis
                            illo fugit officiis distinctio culpa officia totam atque exercitationem 
                            inventore repudiandae? 
                        </p>   
                        </p>
                    </div>

                    <div className="about">
                        <h2 className="title2">Experience</h2>

                        <div className="box">
                            <div className="year_company">
                                <h5>2020 - Present</h5>
                                <h5>Company Name</h5>
                            </div>
                            <div className="text">
                                <h4>Senior UX Developer</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="year_company">
                                <h5>2018 - 2020</h5>
                                <h5>Company Name</h5>
                            </div>
                            <div className="text">
                                <h4>UX Developer</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="year_company">
                                <h5>2016 - 2018</h5>
                                <h5>Company Name</h5>
                            </div>
                            <div className="text">
                                <h4>Junior UX Developer</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>  

                    </div>

                    
                    <div className="about skills">
                        <h2 className="title2">Compétences</h2>

                        <div className="box">
                            <h4>HTML</h4>
                            <div className="percent">
                                <div className="htmlws30"></div>
                            </div>
                        </div>
                        <div className="box">
                            <h4>CSS</h4>
                            <div className="percent">
                                <div className="cssws45"></div>
                            </div>
                        </div>
                        <div className="box">
                            <h4>JavaScript</h4>
                            <div className="percent">
                                <div className="jsws70"></div>
                            </div>
                        </div>
                        <div className="box">
                            <h4>Photoshop</h4>
                            <div className="percent">
                                <div className="phws40"></div>
                            </div>
                        </div>
                        <div className="box">
                            <h4>Illustrator</h4>
                            <div className="percent">
                                <div className="ilws60"></div>
                            </div>
                        </div>
                        <div className="box">
                            <h4>Adobe XD</h4>
                            <div className="percent">
                                <div className="adw70"></div>
                            </div>
                        </div>

                    </div>

                    <div className="about interest">
                        <h2 className="title2">Interests</h2>
                        <ul>
                            <li>BasketBall</li>
                            <li>Musique</li>
                            <li>Lecture</li>
                        </ul> 
                    </div>

                </div>
            </div>
    )
}

Model3.displayName = 'model3'

export default Model3