import React, {useState} from 'react'
import { useTranslation } from "react-i18next";

function Information({info, handleInfo}) {
    const [showInfoField, setShowInfoField] = useState(false);
    const [t, i18n] = useTranslation('global');
    const personnel_info = ['name', 'firstname', 'tel', 'email', 'postal_address'];
    const placeholderValue = ['nom', 'Prénom', 'Téléphone', 'Email', 'Addrèsse postale'];

    return (
        <section className='personnal_info area'>
            <h3 className='title mix'>
                <span>{t('cv.info')}</span>
                <button className='add' onClick={()=>{setShowInfoField(!showInfoField)}} > {showInfoField ? 'v' : '^'}</button>
            </h3>
            {
                showInfoField && 
                <div className='container_div_wrapper'>
                {
                    personnel_info.map((name, index) => (
                    <div className='div_wrapper_input' key={index}>
                        <input name={name} type={name == 'day' ? 'date' : 'text'} placeholder={placeholderValue[index]} onChange={handleInfo} defaultValue={info[name]} />
                    </div>
                    ))
                }
                </div>
            }
        </section>
    )
}

export default Information