import axios from "axios";
import Swal from "sweetalert2";



const DeleteDoc = async (url, set, id) => {
    try {
        const res = await axios.delete(url);
        Swal.fire({icon : 'success', title : '', text : res.data.message});
        set(oldVal => (oldVal?.filter(item => item?._id !== id)))
      } catch (error) {
        Swal.fire({icon : 'error', title : '', text : 'Desolé une erreur est survenue'});
      }
}

export default DeleteDoc;