import React, { useEffect, useState } from 'react';
import './../styles/createLdm.css';
import Ldm from '../components/Ldm';
import CreateLdmHead from '../components/CreateLdmHead';
import { useTranslation } from "react-i18next";


function CreateLdm() {

  const [info, setInfo] = useState({});
  const [content, setContent] = useState({});
  const [object, setObject] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [t, i18n] = useTranslation('global');

  const personnel_info = ['name', 'firstname', 'email', 'tel', 'postal_address', 'country', 'devise', 'city', 'receiver', 'day'];
  const placeholderValue = [t('ldm.name'), t('ldm.firstname'),  t('ldm.email'), t('ldm.tel'), t('ldm.postal'), t('ldm.country'), t('ldm.devise') , t('ldm.city'), t('ldm.receiver'), 'Date']
  const body = ['introduction', 'motivation', 'compatibility', 'skill', 'experience', 'conclusion', 'greeting']
  const bodyValue = [t('ldm.intro'), t('ldm.motivation'), t('ldm.compatibility'), t('ldm.skill'), t('ldm.experience'), t('ldm.conclusion'), t('ldm.greeting')]


  

  useEffect(()=>{
    let data = localStorage.getItem('data');
    data = JSON.parse(data);
    let info = data?.info;
    let content = data?.content;
    let object = data?.object;
    setInfo({...info});
    setContent({...content});
    setObject(object)
  }, []);


  const handleInfo = (event) =>{
    const name = event.target.name;
    const value = event.target.value;

    setInfo(values => ({...values, [name] : value}));

    const allData = {info, content, object};
    localStorage.setItem('data', JSON.stringify(allData))
  }

  const handleContent = (event) =>{
    const name = event.target.name;
    const value = event.target.value;

    setContent(values => ({...values, [name] : value}));
    const allData = {info, content, object};
    localStorage.setItem('data', JSON.stringify(allData));
  }



  return (
    <div id='main_wrapper'>
      <CreateLdmHead data={{information : info, object, content}}/>

      <main className='container_create_ldm'>
        
        <section className='wrapper_editor'>

          <section className='personnal_info'>
            <p className='title mix'>
              <span className='title' >{t('ldm.info')}</span>
              <button className='add' onClick={()=>{setShowInfo(!showInfo)}} > {showInfo ? 'v' : '^'}</button>
            </p>
            {
              showInfo &&
              <div className='container_div_wrapper'>
                {
                  personnel_info.map((name, index) => (
                    <div className='div_wrapper_input' key={index}>
                      <input className='personnal-input' name={name} type={name === 'day' ? 'date' : 'text'} placeholder={placeholderValue[index]} onChange={handleInfo} value={info[name]} />
                    </div>
                  ))
                }
              </div>
            }
          </section>

          <section className='object'>
            <p className='title'>{t('ldm.object')}</p>
            <div className='div_wrapper_input'>
              <input name='object' type='text' className='object_input' onChange={(event => {setObject(event.target.value)})} value={object} />
            </div>
          </section>

          {
            body.map((name, index) => (
              <section key={index} >
                <p className='title'>{bodyValue[index]}</p>
                <div className='div_wrapper_input'>
                  <textarea name={name} rows={3} className='body_textarea' onChange={handleContent} value={content[name]}></textarea>
                </div>
              </section>
            ))
          }
        </section>


        <section className='wrapper_result'>
          <div>
            <Ldm info={info} object={object} content={content}/>
          </div>

        </section>
      </main>
    </div>
  )
}

export default CreateLdm;