import React, { useEffect, useState, Suspense } from 'react'
import './../styles/list.css'
import GetData from '../helpers/GetData'
import ImportAll from '../helpers/ImportAll'
import {FaTrash} from 'react-icons/fa';
import DeleteDoc from '../helpers/DeleteDoc';

function AdminCv() {

    const [cv, setCv] = useState([]);
    const [NomModel, setNomModel] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(()=>{GetData('/all_cv', setCv)}, [])


    //cette fonction appeler permettra d'importer automatiquement tous les composant react correspondant au modele de cv
    const components = ImportAll(require.context('./../modelUpdate/cv', false, /\.js$/));
    

    return (
      <div className='wrapper_all'>
        <div className='wrapper_list'>

          <div className='list'>
            <h2 className='title_list'>Tous les cv({cv.length})</h2>
            {cv.map((cv, i) => (
              <div onClick={()=>{setNomModel(cv?.nom_model); setIndex(i)}} key={cv?._id}>
                <p>{cv?._id}</p>
                <span onClick={()=>{DeleteDoc(`/delete_cv/${cv?._id}`,setCv, cv?._id)}}>
                  <FaTrash/>
                </span>
              </div>
            ))}
          </div>

          <div className='wrapper_table'>
            {
              NomModel &&
              <Suspense fallback={<div>Chargement...</div>}>
                  {components.map((Component, i) => {
                      if (Component.name === NomModel) {
                          return (
                              <div id='my_cv' key={i}>
                                  <Component key={i}  data={cv[index]}/>
                              </div>
                          );
                      }
                      return null;
                  })}
              </Suspense>
            }
          </div>
        </div>
      </div>
    )
}

export default AdminCv;