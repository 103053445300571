import React, { useContext } from 'react';
import UserContext from '../context/UserContext';
import { Navigate, Outlet } from 'react-router-dom';

function Admin() {

    const isAuth = useContext(UserContext);

    return isAuth?.permission ? <Outlet></Outlet> : <Navigate to='/' />

}

export default Admin;