import React, { useContext } from 'react'
import UserContext from '../context/UserContext'
import { Navigate, Outlet } from 'react-router-dom';

function CheckStateUser() {

    const isAuth = useContext(UserContext);

    return isAuth?.isAuth  ? <Outlet></Outlet> : <Navigate to='/page/login'/>
}

export default CheckStateUser