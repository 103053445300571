import axios from 'axios';
import Swal from 'sweetalert2';

const GetData = async (url, setData ) => {
    try {
      const res = await axios.get(url);
      setData(res.data);
    } catch (error) {
      
      if (error?.response) {
        Swal.fire({icon : 'error', title : 'Erreur', text : error?.response.data.message});
      }
      else{
        Swal.fire({icon : 'error', title : 'Erreur', text : 'Desolé une erreur de survenue'});
      }
    }
}

export default GetData;