import axios from 'axios';
import Swal from 'sweetalert2';

const GetCurrentCv = async (url, setData , redirect) => {
    try {
      const res = await axios.get(url);
      setData(res.data);
    } catch (error) {
      
      if (error?.response) {
        Swal.fire({icon : 'error', title : 'Erreur', text : error?.response.data.message});
        redirect(-1);
      }
      else{
        Swal.fire({icon : 'error', title : 'Erreur', text : 'Desolé une erreur de survenue'});
        redirect(-1)
      }
    }
}

export default GetCurrentCv;