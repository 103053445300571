import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';



/**
 * 
 * @param 
 * inputLangages : c'est le tableau qui sera parcouri pour afficher les champ qui permettent l'ajout d'une langue et son niveau
 * setInputLangages : permettait d'ajouter ou de retirer des champs dans le tableau des langues
 * handleAddInput : une fonction qui permet de modifier setInputLangages en ajoutant de nouvel element
 * listLangage : cest un tableau qui contient la liste des langues et leurs niveau valider qui seront passe en props au composant model de cv
 * setListLangage : permet de d'ajouter ou retirer des langues dans la liste des langues
 * @returns 
 */
function Langue({inputLangages, setInputLangages, handleAddInput, listLangage, setListLangage}) {

    const [langage, setLangage] = useState({langage : '', level : 1});
    const [showInput, setShowInput] = useState(false);
    const [t, i18n] = useTranslation('global');

    /**
     * lors de la creation du composant on compte la liste des langues valide et on cree des champs de formulaire
     * pour chacun d'eux qui sera pre-rempli 
     */
    useEffect(()=>{
        let val = listLangage?.map((el,index) =>({id : index}));
        setInputLangages(val);
    }, [showInput])


    /**
     * cette function permet de recuperer les nouvelles langues et niveau qui doivent etre ajouter
     */
    const handleLangage = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
    
        setLangage(values => ({...values, [name] : value}));
    }


    /**
     * cette function permet de valider une langue saisi l'ajoute dans la liste des langues valide
     * et mettre a jour le localStrorage
     */
    const handleClick = (indexInputLangage) =>{

        //on verifie si une information existe dans le champ hobby
        if(langage?.langage && langage?.level){
             //ici on verifie si le champ valider est un nouveau ou doit etre modifier
            if (listLangage[indexInputLangage]) {
                const newListLangage = [...listLangage];
                newListLangage.splice(indexInputLangage, 1, langage);

                setListLangage(newListLangage);
                let data = localStorage.getItem('cv_data');
                data = JSON.parse(data);
                data?.listLangage?.splice(indexInputLangage, 1, langage);
                localStorage.setItem('cv_data', JSON.stringify(data))
                setLangage({});
                
            } else {
                setListLangage((oldVal) => [...oldVal, langage]);
                let data = localStorage.getItem('cv_data');
                data = JSON.parse(data);
                data?.listLangage?.push(langage)
                localStorage.setItem('cv_data', JSON.stringify(data))
                setLangage({});
            }

        }
    }

    return (
        <section className='area'>
            <h3 className='title mix'>
                <span>{t('cv.language')}</span>
                <div>
                    <button className='add' onClick={()=>{handleAddInput(setInputLangages); setShowInput(true)}} >+</button>
                    <button className='add' onClick={()=>{setShowInput(!showInput)}}> {showInput ? 'v' : '^'}</button>
                </div>
            </h3>

            {
                showInput &&    
                <div>
                    {inputLangages.map((field, index) => (
                        <div className='div_wrapper_input' key={index}>
                            <p>
                                <label htmlFor="langage">Langue </label>
                                <span className='require'>*</span>
                            </p>
                            <input className='body_textarea' id='langage' name='langage' onChange={handleLangage} defaultValue={listLangage[index]?.langage} />
                            <p>
                                <label htmlFor="level">Niveau : </label>
                            </p>
                            <p><input type="range" id="level" name="level" min="1" max="3" onChange={handleLangage} defaultValue={listLangage[index]?.level} /></p>
                            <div className='container_btn_manager'>
                                <button onClick={() => {
                                    setInputLangages(inputLangages.filter((ele, i) => ele?.id !== field?.id));
                                    listLangage?.splice(index, 1);
                                    setListLangage(listLangage);
                                    
                                    let data = localStorage.getItem('cv_data');
                                    data = JSON.parse(data);
                                    data?.listLangage?.splice(index, 1);
                                    localStorage.setItem('cv_data', JSON.stringify(data));
                                }}>{t('cv.delete')}</button>
                                <button onClick={()=>{handleClick(index)}}>{t('login.validate')}</button>
                            </div>
                        </div>
                    ))?.reverse()}
                </div>
            }
        </section>
    )
}

export default Langue