import React from 'react'
import './model5.css'
import { FaEnvelope, FaMapSigns, FaMobileAlt } from 'react-icons/fa';

const Model5 = () => {
    return (
        <div className="resume_model_cendrine">
            <div className="resume_top">

                <div className="resume_profile">
                    <img src="https://i.imgur.com/eCijVBe.png" alt="profile_pic"/>
                </div>

                {/* La section Information personnelle */}
                <div className="resume_info">
                        <div className="title">
                            <span className="bold">KABORE</span>
                            <span className="bold">STEPHANE</span>
                        </div>
                        <ul>
                            <li>
                                <div className="data"> <FaMobileAlt/>+266 7x-xx-xx-xx</div>
                            </li>
                            <li>
                                <div className="data"> <FaEnvelope/>stephane@gmail.com</div>          
                            </li>
                            <li>
                                <div className="data"> <FaMapSigns/>Rue 21, Bobo Dioulasso BF</div>
                            </li>
                        </ul>
                    </div>
                </div>


            <div className="resume_bottom">
                <div className="resume_bottom_left">

                    {/* La section Profile */}
                    <div className="resume_item resume_about">
                        <div className="title">
                            <p className="bold">Profil</p>
                        </div>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis illo fugit officiis distinctio culpa officia totam atque exercitationem inventore repudiandae? </p>
                    </div>

                    
                    {/* La section experience professionnelle */}
                    <div className="resume_item">
                        <div className="title">
                            <p className="bold">Experience Professionnelle</p>
                        </div>

                        <ul>
                                    <li>
                                        <div className="date">2013 - 2015</div> 
                                        <div className="info">
                                            <p className="semi-bold">Lorem ipsum dolor sit amet.</p> 
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, voluptatibus!</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="date">2015 - 2017</div>
                                        <div className="info">
                                            <p className="semi-bold">Lorem ipsum dolor sit amet.</p> 
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, voluptatibus!</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="date">2017 - Present</div>
                                        <div className="info">
                                            <p className="semi-bold">Lorem ipsum dolor sit amet.</p> 
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, voluptatibus!</p>
                                        </div>
                                    </li>
                        </ul>

                    </div>

                    {/* La section Formation */}
                    <div className="resume_item">
                        <div className="title">
                            <p className="bold">Formation</p>
                        </div>

                        <ul>
                                    <li>
                                        <div className="date">2010 - 2013</div> 
                                        <div className="info">
                                            <p className="semi-bold">Web Designing (Texas University)</p> 
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, voluptatibus!</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="date">2000 - 2010</div>
                                        <div className="info">
                                            <p className="semi-bold">Texas International School</p> 
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, voluptatibus!</p>
                                        </div>
                                    </li>
                        </ul>

                    </div>



                </div>

                

                <div className="resume_bottom_right">
                    {/* La section Competence */}
                    <div className="resume_item">
                        <div className="title">
                            <p className="bold">Compétences</p>
                        </div>

                        <ul>
                                <li>
                                    <div className="skill_name">HTML : <span>2/3</span></div>
                                </li>
                                <li>
                                    <div className="skill_name">CSS : <span>2/3</span></div>
                                </li>
                                <li>
                                    <div className="skill_name">SASS : <span>2/3</span></div>
                                </li>
                                <li>
                                    <div className="skill_name"> JS : <span>2/3</span></div>
                                </li>
                                <li>
                                    <div className="skill_name">JQUERY : <span>2/3</span></div>
                                </li>
                        </ul>
                    </div>


                    {/* La section Langues */}
                    <div className="resume_item">
                        <div className="title">
                            <p className="bold">Langues</p>
                        </div>

                        <ul>
                                    <li>
                                        <div className="data">
                                            <p className="semi-bold">Dioula</p>
                                            <p>Très bien</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="data">
                                            <p className="semi-bold">Francais</p>
                                            <p>Bien</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="data">
                                            <p className="semi-bold">Anglais</p>
                                            <p>intermediaire</p>
                                        </div>
                                    </li>
                        </ul>
                    </div>

                    {/* La section Centre d'intéret */}
                    <div className="resume_item">
                        <div className="title">
                            <p className="bold">Centre d'intéret</p>
                        </div>

                        <ul className='m5-hobby'>
                            <li>BasketBall</li>
                            <li>Musique</li>
                            <li>Lecture</li>
                        </ul> 
                    </div>
                </div>
            </div>
        </div>
    )
}

Model5.displayName = 'model5'

export default Model5;