import React from 'react'
import { FaEnvelope,  FaMobileAlt } from 'react-icons/fa';
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';
import './../../model/cv/model2.css';

const Model2 = ({data}) => {

    const [t, i18n] = useTranslation('global');

    return (
        <div className="resume_wrapper" id='min_size'>
            <div className="resume_left">
                {
                    data?.photo &&
                    <div className="resume_image">
                         <img src={data?.photo} alt="profile_pic"/>
                    </div>
                }


                <div className="resume_bottom">

                    {/** profile */}
                    {
                        (data?.profile) &&
                        <div className="resume_item resume_profile">
                            <div className="resume_title">{t('cv.profile')}</div>
                            <div className="resume_info">
                            {
                                (data?.profile)
                            }
                            </div>
                        </div>
                    }


                    {
                        (data?.info?.postal_address) &&
                        <div className="resume_item resume_address">
                            <div className="resume_title">Address</div>
                            <div className="resume_info">
                                {data?.info?.postal_address }
                            </div>
                        </div>
                    }


                    {
                        (data?.info?.tel || data?.info?.email) &&
                        <div className="resume_item resume_contact">
                            <div className="resume_title">Contact</div>
                            {
                                data?.info?.tel &&
                                <div className="resume_info">
                                    <div className="resume_subinfo"> <FaMobileAlt/> {data?.info?.tel}</div>
                                </div>
                            }
                            {
                                (data?.info?.email) &&
                                <div className="resume_info">
                                    <div className="resume_subinfo"> <FaEnvelope/> <a href={`mailto:${data?.info?.email}`} style={{color : '#c5c5c5'}} >{data?.info?.email}</a></div>
                                </div>

                            }


                        </div>
                    }



                    {
                        (data?.skill?.length > 0) && 
                        <div className="resume_item resume_skills">

                            <div className="resume_title">{t('cv.skill')}</div>
                            {
                                <div className="resume_info">
                                    {data?.skill?.map((skill, i) => (
                                        <div className="skills_list" key={i}>
                                            <div className="skills_left">{skill?.skill}</div>
                                            <div className="skills_bar">
                                                <p>
                                                    <span style={{width : `${(parseInt(skill?.level) * 30)}%`}}></span>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div> 
                            }

                        </div>
                    }


                    {
                        (data?.langages?.length > 0) && 
                                        <div className="resume_item resume_skills">

                                                <div className="resume_title">{t('cv.language')}</div>
                                                <div className="resume_info">
                                                        {
                                                            data?.langages?.map((langage, i) => (
                                                                <div key={i} className="skills_list">
                                                                    <div className="skills_left">{langage?.langage}</div>
                                                                </div>
                                                            ))
                                                        }
                                                </div>
                                        </div>
                    }

                </div>
            </div>

            <div className="resume_right">
                {
                    (data?.info?.name || data?.info?.firstname) &&
                    <div className="resume_item resume_namerole">
                        <div className="name">
                            {data?.info?.name && <span className="bold">{`${data?.info?.name} `}</span> }
                            {data?.info?.firstname && <span className="bold">{data?.info?.firstname}</span>}
                        </div>
                    </div>
                }



                {
                    (data?.formations?.length > 0) && 
                    <div className="resume_item resume_education">
                    <div className="resume_title">{t('cv.education')}</div>

                    {
                            <div className="resume_info">
                                {data?.formations?.map((formation, i) => (
                                    <div className="resume_data" key={i}>
                                        <div className="year">{dayjs(formation?.begin).format('DD/MM/YYYY')} {dayjs(formation?.end).format('DD/MM/YYYY')}</div> 
                                        <div className="content">
                                            <p>{formation?.formation} ({formation?.etablissement} {formation?.city})</p> 
                                            <p>{formation?.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div> 
                    }
                    </div>
                }



                {
                    (data?.experiences?.length > 0) &&
                    <div className="resume_item resume_experience">
                    <div className="resume_title">{t('cv.experience')}</div>

                    {
                            <div className="resume_info">
                                {data?.experiences?.map((experience, i) => (
                                    <div className="resume_data" key={i}>
                                        <div className="year">{ dayjs(experience?.begin).format('DD/MM/YYYY')} {dayjs(experience?.end).format('DD/MM/YYYY')}</div> 
                                        <div className="content">
                                            <p>{experience?.job} ({experience?.etablissement} {experience?.city})</p> 
                                            <p>{experience?.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div> 
                    }

                    </div>
                }


                {
                    (data?.hobby?.length > 0) &&
                    <div className="resume_item resmue_interests">
                        <div className="resume_title">{t('cv.hobby')}</div>
                        {
                                <ul>
                                    {data?.hobby?.map((hobby, i) => (
                                            (<div className="int_data" key={i}>{hobby}</div>)
                                    ))}
                                </ul> 
                        } 
                    </div>
                }

            </div>
        </div>
    )
}

Model2.displayName = 'model2'

export default Model2