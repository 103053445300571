import React from 'react';
import './model6.css';
import model6Profile from './../../images/model6Profile.jpeg';

const Model6 = () => {
    return (
        <div className='model6-container'>
            <div className="left_Side">
                <div className="profileText">
                    <div className="imgBx">
                        <img src={model6Profile} alt=''/>
                    </div>
                    <h2>Emaan Bari<br/><span>Computer Scientist</span></h2>
                </div>

                <div className="contactInfo">
                    <h3 className="title">Contact Info</h3>
                    <ul>
                        <li>
                            <span className="icon"><i className="fa fa-phone" aria-hidden="true"></i></span>
                            <span className="text">0900 786 01</span>
                        </li>
                        <li>
                            <span className="icon"><i className="fa fa-envelope" aria-hidden="true"></i></span>
                            <span className="text">emmi@gmail.com</span>
                        </li>
                        <li>
                            <span className="icon"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                            <span className="text">56th street,california</span>
                        </li>
                    </ul>
                </div>

                <div className="contactInfo education">
                    <h3 className="title">EDUCATION</h3>
                    <ul>
                        <li>
                            <h5>2017 - 2019</h5>
                            <h4> Matric in Science</h4>
                            <h4>School Name </h4>
                        </li>
                        <li>
                            <h5>2019 - 2021</h5>
                            <h4> Intermediate in Maths</h4>
                            <h4>College Name</h4>
                        </li>
                        <li>
                            <h5>2021 - Now</h5>
                            <h4>Undergraduate in Computer Science</h4>
                            <h4>University Name</h4>
                        </li>
                    </ul>
                </div>

                <div className="contactInfo language">
                    <h3 className="title">LANGUAGES</h3>
                    <ul>
                        <li>
                            <span className="text">English</span>
                            <span className="percent"></span>
                            <div style={{width : "90%"}} ></div>
                        </li>
                        <li>
                            <span className="text">Urdu</span>
                            <span className="percent"></span>
                            <div style={{width : "80%"}}></div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='right_Side'>
                <div className="about">
                    <h2 className="title2">Profile</h2>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro exercitationem nesciunt,<br/> 
                    tenetur architecto omnis maxime delectus quae quo reprehenderit quas laudantium.Itaque sequi commodi vero suscipit reiciendis ea aspernatur cum.Lorem ipsum, 
                    dolor sit amet consectetur adipisicing elit. Porro exercitationem nesciunt,tenetur architecto omnis maxime delectus quae quo reprehenderit quas laudantium. 
                    <br/>Itaque sequi commodi vero suscipit reiciendis ea aspernatur cum.</p>
                </div>

                <div className="about">
                    <h2 className="title2">Experience</h2>
                    <div className="box">
                        <div className="year_company">
                            <h5>2019 - 2021</h5>
                            <h5>Company Name</h5>
                        </div>
                        <div className="text">
                            <h4>Senior Web Developer</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro exercitationem nesciunt, tenetur architecto omnis </p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="year_company">
                            <h5>2021 - present</h5>
                            <h5>Company Name</h5>
                        </div>
                        <div className="text">
                            <h4>Data Analyist</h4>
                            <p>Lorem ipsum,dolor sit amet consectetur adipisicing elit. Porro exercitationem nesciunt,tenetur architecto omnis </p>
                        </div>
                    </div>
                </div>

                <div className="about skills">
                    <h2 className="title2">Professional Skills</h2>

                    <div className="box">
                        <h4>Html</h4>
                        <div className="percent">
                            <div style={{width : "95%"}}></div>
                        </div>
                    </div>

                    <div className="box">
                        <h4>CSS</h4>
                        <div className="percent">
                            <div style={{width : "70%"}}></div>
                        </div>
                    </div>

                    <div className="box">
                        <h4>JAVASCRIPT</h4>
                        <div className="percent">
                            <div style={{width : "95%"}}>
                        </div>
                    </div>
        
                    </div>

                    <div className="box">
                        <h4>PYTHON</h4>
                        <div className="percent">
                            <div style={{width : "75%"}}></div>
                        </div>
                    </div>
                </div>

                <div class="About interest">
                    <h2 class="title2">Interest</h2>
                    <ul>
                        <li>Trading</li>
                        <li>Developing</li>
                        <li>Gaming</li>
                        <li>Business</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

Model6.displayName = 'Model6';

export default Model6;