import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import CountWord from '../../helpers/CountWord';
import Swal from 'sweetalert2';


/**
 * 
 * @param 
 * inputExperiences : c'est le tableau qui sera parcouri pour afficher les champ qui permettent l'ajout d'experience
 * setInputExperiences : permettait d'ajouter ou de retirer des champs au niveau de l'experience
 * handleAddInput : permet de modifier setInputExperiences en ajoutant de nouvel element
 * listExperience : cest un tableau qui present la liste des experience valider qui seront passe en props au composant model de cv
 * setListExperience : permet de d'ajouter ou retirer des experience dans la liste des experiences
 * @returns 
 */
function Experience({inputExperiences, setInputExperiences, handleAddInput, listExperience, setListExperience }) {

    //permet de recuperer l'experience courant saisi
    const [experience, setExperience] = useState({
        job : "",
        description : "",
        etablissement : "",
        city : "",
        begin : "",
        end : "",
    });
    //permettra d'afficher ou de cacher les champs generer
    const [showInput, setShowInput] = useState(false);

    const [t, i18n] = useTranslation('global');


    //pendant le montage et la modification du composant on met nos deux tableau a la meme longueur
    // pour fiare de sorte que la liste des experiences valider soit egal a la liste des champs generer
    useEffect(()=>{
        let val = listExperience?.map((el,index) =>({id : index}));
        setInputExperiences(val);
    }, [showInput])


    //permet la recuperation des experiences saisis
    const handleExperience = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
    
        setExperience(values => ({...values, [name] : value}));
    }


    //cette fonction verifie si tous les info concernant l'experience est fourni met a jour la listes des experiences valide
    //met aussi a jour le localstorage
    // et vide le state contenant l'experience
    const handleClick = (indexInputExperience) =>{
        //si on a un element dans la liste des Experience sur le cv on doit le modifier
        if (listExperience[indexInputExperience]) {

            for (const key in experience) {
                if (experience[key]?.trim() === '' || experience[key] === undefined || experience[key] === null ) {
                    experience[key] = listExperience[indexInputExperience][key]
                }

                if(experience?.job && experience?.etablissement && experience?.city && experience?.begin && experience?.end && experience?.description ){

                    // ceci je verifie si la description ne depasse pas 60 mots
                    if (CountWord(experience?.description) <= 60) {

                        //je cree un nouveau tableau dans lequel je remplace la formation modifier par ses nouveaux valeur
                        const newListExperience = [...listExperience];
                        newListExperience.splice(indexInputExperience, 1, experience);
                        //cette nouvelle liste remplacera la liste des formation dans le cv
                        setListExperience(newListExperience);
        
                        //ces instructions recupere les info actuelle du cv sur le local storage
                        //et le met a jour en ajoutant l'experience actuelle
                        let data = localStorage.getItem('cv_data');
                        data = JSON.parse(data);
                        data?.listExperience?.splice(indexInputExperience, 1, experience);
                        localStorage.setItem('cv_data', JSON.stringify(data))
                        setExperience({
                            job : "",
                            description : "",
                            etablissement : "",
                            city : "",
                            begin : "",
                            end : "",
                        });
                    } else {
                        Swal.fire({
                            title: '',
                            text: t('cv.length_alert'),
                            icon: 'error',
                        })
                    }
        
                } 
            }
            
        }
        //sinon on doit l'ajouter dans la liste des formations dans le cv 
        else {
            if(experience?.job && experience?.etablissement && experience?.city && experience?.begin && experience?.end && experience?.description ){

                // ceci je verifie si la description ne depasse pas 60 mots
                if (CountWord(experience?.description) <= 60) {
                    //cette ajoute l'experience actuelle sur la partie
                    setListExperience((oldVal) => [...oldVal, experience]);
    
                    //ces instructions recupere les info actuelle du cv sur le local storage
                    //et le met a jour en ajoutant l'experience actuelle
                    let data = localStorage.getItem('cv_data');
                    data = JSON.parse(data);
                    data?.listExperience?.push(experience)
                    localStorage.setItem('cv_data', JSON.stringify(data))
                    setExperience({
                        job : "",
                        description : "",
                        etablissement : "",
                        city : "",
                        begin : "",
                        end : "",
                    });
                } else {
                    Swal.fire({
                        title: '',
                        text: t('cv.length_alert'),
                        icon: 'error',
                    })
                }
    
            }         
        }
    }

    return (
        <section className='area'>
            <h3 className='title mix'>
                <span>{t('cv.experience')}</span>
                <div>
                    <button className='add' onClick={()=>{handleAddInput(setInputExperiences); setShowInput(true)}} >+</button>
                    <button className='add' onClick={()=>{setShowInput(!showInput)}}> {showInput ? 'v' : '^'}</button>
                </div>
            </h3>

            {
                showInput &&
                <div >
                    {inputExperiences?.map((field, index) => (
                        <div key={index}>
                            <div className='container_div_wrapper' >
                                <div className='div_all div_padding'>
                                    <p>
                                        <label htmlFor='job'>{t('cv.job')}</label>
                                        <span className='require'>*</span>
                                    </p>
                                    <input id='job' type='text' name='job' onChange={handleExperience} defaultValue={listExperience[index]?.job}/>
                                </div>
                                <div className='div_padding'>
                                    <p>
                                        <label htmlFor='etablissement'>{t('cv.company')}</label>
                                        <span className='require'>*</span>
                                    </p>
                                    <input id='etablissement' type='text' name='etablissement' onChange={handleExperience} defaultValue={listExperience[index]?.etablissement} />
                                </div>
                                <div className='div_padding'>
                                    <p>
                                        <label htmlFor='ville'>{t('cv.city')}</label>
                                        <span className='require'>*</span>
                                    </p>
                                    <input id='ville' type='text' name='city' onChange={handleExperience} defaultValue={listExperience[index]?.city} />
                                </div>
                                <div className='div_padding'>
                                    <p>
                                        <label htmlFor='begin' >{t('cv.begin')}</label>
                                        <span className='require'>*</span>
                                    </p>
                                    <input id='begin' type='date' name='begin'  onChange={handleExperience} defaultValue={listExperience[index]?.begin}/>
                                </div>
                                <div className='div_padding'>
                                    <p>
                                        <label htmlFor='end' >{t('cv.end')}</label>
                                        <span className='require'>*</span>
                                    </p>
                                    <input id='end' type='date' name='end'  onChange={handleExperience} defaultValue={listExperience[index]?.end} />
                                </div>
                                <div className='div_all div_padding'>
                                    <p>
                                        <label htmlFor='end'>{t('cv.task')} ({t('cv.max')})</label>
                                        <span className='require'>*</span>
                                    </p>
                                    <textarea rows={8} name='description' onChange={handleExperience} defaultValue={listExperience[index]?.description}></textarea>
                                </div>
                                
                            </div>
                            <div className='container_btn_manager'>
                                    <button onClick={() => {
                                        setInputExperiences(inputExperiences.filter((ele, i) => ele?.id !== field?.id));
                                        listExperience?.splice(index, 1);
                                        setListExperience(listExperience)

                                        let data = localStorage.getItem('cv_data');
                                        data = JSON.parse(data);
                                        data?.listExperience?.splice(index, 1);
                                        localStorage.setItem('cv_data', JSON.stringify(data))
                                    }}>{t('cv.delete')}</button>
                                    <button onClick={()=>{handleClick(index)}}>{t('login.validate')}</button>
                                    
                            </div>
                        </div>
                    ))}
                </div>
            }
        </section>
    )
}

export default Experience