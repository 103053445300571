import React, { Suspense } from 'react'
import './../styles/cvModel.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom'
import ImportAll from '../helpers/ImportAll'
import { useTranslation } from 'react-i18next'

function CvModel() {

  const [t, i18n] = useTranslation('global');
  const redirect = useNavigate();


  const components = ImportAll(require.context('./../model/cv', false, /\.js$/))

  return (
    <>
      <div className='cv_model'>
        <Header/>
        <h1>{t("templateCv.title")}</h1>
        <section className='container_cv'>
          <Suspense fallback={<div>Chargement...</div>}>
            {components.map((Component, index) => (
              <div className='div_cv' key={index} onClick={() => {redirect(`/create_cv/${Component?.displayName}/none`)}} style={{cursor: 'pointer'}}>
                <Component />
              </div>
            ))}
          </Suspense>
        </section>
      </div>
      <Footer/>
    </>
  )
}

export default CvModel