import React, { useState } from 'react'

function SelectPhoto({setSrcPhoto}) {

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.onloadend = () =>{
            const result = reader.result
            setSrcPhoto(result);
            localStorage.setItem('uploadedImg', result)
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }


    return (
        <section className='area'>
            <h3 className='title mix'>
                <span>Photo</span>
            </h3>
            <div>
                <div className='div_wrapper_input'>
                    <input type='file' accept='image/*' name='photo' onChange={handleImageChange} />
                </div>
            </div>
        </section>
    )
}

export default SelectPhoto