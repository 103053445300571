import React from 'react';
import '../styles/footer.css'

function Footer() {
  return (
    <footer className = "footer bg-dark">
      <div className="container">
          <div className = "footer-content text-center">
              <p className="fs-15">&copy;Copyright 2024. Tous droits réservés - <span>OZONE S.A.R.L</span></p>
          </div>
      </div>
  </footer>
  )
}

export default Footer