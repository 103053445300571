import React, { useEffect, useState } from 'react'
import GetData from '../helpers/GetData';
import './../styles/list.css'
import axios from 'axios';
import Swal from 'sweetalert2';

function AdminUser() {

  const [users, setUsers] = useState([]);
  const [detail, setDetail] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [role, setRole] = useState('user');

  useEffect(()=> {
    GetData('/users', setUsers);
  }, []);

  const handleDel = async (id) =>{
    try {
      const res = await axios.delete('/delete_user/' + id);
      Swal.fire({icon : 'success', title : 'Ok', text : res.data.message});
      const nd = users.filter((user) => (user._id != id));
      setUsers(nd)

    } catch (error) {
      if (error?.response) {
        Swal.fire({icon : 'error', title : 'Erreur', text : error?.response.data.message});
      }
      else{
        Swal.fire({icon : 'error', title : 'Erreur', text : 'Desolé une erreur de survenue'});
      }
    }
  }


  const handleEdit = async (id, role) =>{
    try {
      const res = await axios.put('/change_role/' + id, {role});
      Swal.fire({icon : 'success', title : 'Ok', text : res.data.message});

    } catch (error) {
      if (error?.response) {
        Swal.fire({icon : 'error', title : 'Erreur', text : error?.response.data.message});
      }
      else{
        Swal.fire({icon : 'error', title : 'Erreur', text : 'Desolé une erreur de survenue'});
      }
    }
  }

  return (
    <div className='wrapper_all'>
      {users.length > 0 ? 
      <div className='wrapper_list'>
        
        <div className='list'>
          <h2 className='title_list'>Tous les utilisateurs ({users.length})</h2>
          {users.map((user, i) => (
            <div onClick={()=>{setDetail(user)}} key={i} >
              <p>{user?.name} {user?.firstname}</p>
            </div>
          ))}
        </div>

       

        <div className='wrapper_table'>
        <h2 className='title_list'>Detail</h2>
          <table>
            <tbody>
              <tr>
                <td className='row_head'>Id</td>
                <td>{detail?._id}</td>
              </tr>
              <tr>
                <td className='row_head'>Nom</td>
                <td>{detail?.name}</td>
              </tr>
              <tr>
                <td className='row_head'>Prénom</td>
                <td>{detail?.firstname}</td>
              </tr>
              <tr>
                <td className='row_head'>Email</td>
                <td>{detail?.email}</td>
              </tr>
              <tr>
                <td className='row_head'>Role</td>
                <td>{detail?.role}</td>
              </tr>
              <tr>
                <td className='row_head'>Date</td>
                <td>{detail?.created_at}</td>
              </tr>
            </tbody>
          </table>
          
          { detail?._id &&
            <div className='wrapper_btn'>
              <button className='btn_del' onClick={()=>{handleDel(detail?._id)}}>Suprimer</button>
              <button className='btn_edit' onClick={()=>{setShowForm(!showForm)}} >Editer le role</button>
            </div>
          }

          {(detail?._id && showForm) &&
            <form method='post' className='form_change_role' >
              <select onChange={(e)=>{setRole(e.target.value)}}>
                <option value='user'>user</option>
                <option value='admin'>admin</option>
              </select>
              <div>
                <button type='button' onClick={()=>{handleEdit(detail?._id, role)}}>Appliquer</button>
              </div>
            </form>
          }
        </div>
      </div> : 

      <div>Aucun utilisateur</div>
      }
    </div>
  )
}

export default AdminUser