import React from 'react'
import './model1.css'
import UserIcon from './../../images/user.png'
import { FaEnvelope, FaMapSigns, FaMobileAlt } from 'react-icons/fa';

const Model1 = () => {
    
    
    return (
        <div className="resume">
            <div className="resume_left">

                <div className="resume_profile">
                    <img src={UserIcon} alt="profile_pic"/>
                </div>

                
                <div className="resume_content">
                    {/* La section Information personnelle */}
                    <div className="resume_item resume_info">
                        <div className="title">
                            <span className="bold">KABORE </span>
                            <span className="bold">STEPHANE</span>
                        </div>
                        <ul>
                            <li>
                                <div className="icon">
                                    <FaMobileAlt/>
                                </div>
                                <div className="data">+266 7x-xx-xx-xx </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <FaEnvelope/>
                                </div>
                                <div className="data">stephane@gmail.com </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <FaMapSigns/>
                                </div>
                                <div className="data">Rue 21, Bobo Dioulasso BF</div>
                            </li>
                        </ul>
                    </div>


                    {/* La section Competence */}
                    <div className="resume_item resume_skills">
                        <div className="title">
                            <p className="bold">Compétences</p>
                        </div>

                        <ul>
                                <li>
                                    <div className="skill_name">HTML</div>
                                    <div className="skill_progress">
                                        <span style={{width : '80%'}}></span>
                                    </div>
                                </li>
                                <li>
                                    <div className="skill_name">CSS</div>
                                    <div className="skill_progress">
                                        <span style={{width : '70%'}}></span>
                                    </div>
                                </li>
                                <li>
                                    <div className="skill_name">SASS</div>
                                    <div className="skill_progress">
                                        <span style={{width : '90%'}}></span>
                                    </div>
                                </li>
                                <li>
                                    <div className="skill_name"> JS</div>
                                    <div className="skill_progress">
                                        <span style={{width : '60%'}}></span>
                                    </div>
                                </li>
                                <li>
                                    <div className="skill_name">JQUERY</div>
                                    <div className="skill_progress">
                                        <span style={{width : '88%'}}></span>
                                    </div>
                                </li>
                            </ul>

                    </div>


                    {/* La section Langues */}
                    <div className="resume_item resume_social">
                        <div className="title">
                            <p className="bold">Langues</p>
                        </div>

                        <ul>
                                    <li>
                                        <div className="data">
                                            <p className="semi-bold">Dioula</p>
                                            <p>Très bien</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="data">
                                            <p className="semi-bold">Francais</p>
                                            <p>Bien</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="data">
                                            <p className="semi-bold">Anglais</p>
                                            <p>intermediaire</p>
                                        </div>
                                    </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="resume_right">
                {/* La section Profile */}
                <div className="resume_item resume_about">
                    <div className="title">
                        <p className="bold">Profil</p>
                    </div>
                    <p>
                        Informaticien passionné avec plus de 8 ans d'expérience dans le développement de logiciels, 
                        la gestion de bases de données et l'administration système. Compétences approfondies dans la programmation orientée objet, 
                        les langages de script et les technologies de développement web. Solide background en conception, développement et déploiement 
                        de solutions informatiques robustes et évolutives.
                    </p>
                </div>

                
                {/* La section experience professionnelle */}
                <div className="resume_item resume_work">
                    <div className="title">
                        <p className="bold">Experience Professionnelle</p>
                    </div>

                    <ul>
                                <li>
                                    <div className="date">2013 - 2015</div> 
                                    <div className="info">
                                        <p className="semi-bold">Développeur Senior.</p> 
                                        <p>Concevoir, développer et déployer des applications web utilisant les technologies Java EE, Spring Boot et Angular.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="date">2015 - 2017</div>
                                    <div className="info">
                                        <p className="semi-bold">Ingénieur en systèmes d'information</p> 
                                        <p>Automatiser les tâches récurrentes à l'aide de scripts Shell et PowerShell, réduisant ainsi les erreurs et les temps d'exécution.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="date">2017 - Present</div>
                                    <div className="info">
                                        <p className="semi-bold">Ingénieur Base de donnée</p> 
                                        <p>
                                            Gérer les bases de données Oracle et MySQL, assurant la disponibilité et la performance.
                                            Mettre en œuvre des solutions de sécurité pour protéger les données sensibles et les infrastructures.
                                        </p>
                                    </div>
                                </li>
                    </ul>

                </div>

                {/* La section Formation */}
                <div className="resume_item resume_education">
                    <div className="title">
                        <p className="bold">Formation</p>
                    </div>

                    <ul>

                        <li>                        
                            <div className="date">2010 - 2013</div> 
                                <div className="info">
                                    <p className="semi-bold">Web Design (Bobo Dioulasso)</p> 
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, voluptatibus!</p>
                             </div>
                        </li>
                        <li>
                            <div className="date">2009 - 2010</div>
                            <div className="info">
                                <p className="semi-bold">Master en informatique (Ouagadougou)</p> 
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, voluptatibus!</p>
                            </div>
                        </li>
                    </ul> 

                </div>


                {/* La section Centre d'intéret */}
                <div className="resume_item resume_hobby">
                    <div className="title">
                        <p className="bold">Centre d'intéret</p>
                    </div>
                    
                    <ul>
                        <li>BasketBall</li>
                        <li>Musique</li>
                        <li>Lecture</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
Model1.displayName = "model1";

export default Model1