import React, { useContext, useState} from 'react'
import './../styles/login.css'
import { Link, useNavigate} from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2'
import UserContext from '../context/UserContext';
import fasocv from './../images/fasocv.png'
import { useTranslation } from 'react-i18next';

function Login() {
  const [data, setData] = useState({email : '', password : ''});
  const [error, setError] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [t, i18n] = useTranslation('global');
  const {setIsAuth} = useContext(UserContext);
  const redirect = useNavigate();


  const hanbleData = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setData(values => ({...values, [name] : value}))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!data?.email) {
        setError(t("login.emailReq"))
    }
    else if(!data?.password) {
        setError(t("login.pwdReq"))
    }
    else {
      setSubmitDisabled(true);
      setError('');
      try {
        const res = await axios.post('/login', data);

        localStorage.setItem('token', res.data.token);

        Swal.fire({  
          title: t("login.congratulate"),  
          icon: 'success'
        });
        redirect('/')
        setIsAuth(true);
        
        
      } 
      catch (error) {
        if (error?.response) {
          setError(error?.response.data.message);
        }
        else{
          setError(t("login.error"));
        }

        setSubmitDisabled(false);
      }

    }
}




  return (
    <div className='login'>
      <div className='container_img'>

      </div>
      <div className='container_div_form'>
        <div className='container_form'>
          <div className='icon-container'>
            <img src={fasocv} width='200px' alt=''/>
          </div>
         <h1>{t("login.submit")}</h1>
         <i className='error'>{error}</i>
         <form className='form' onSubmit={handleSubmit} method='post'>
            <p>{t("login.email")}</p>
            <div className='div_input'>
              <input type='email' name='email' onChange={hanbleData}/>
            </div>

            <p>{t("login.password")}</p>
            <div className='div_input' style={{position:"relative"}}>
              <input type='password' name='password' onChange={hanbleData}/>
            </div>

            <div className='div_input'>
              <input type='submit' value={!submitDisabled ? t("login.submit") : t("login.processing")} disabled={submitDisabled} className={!submitDisabled ? 'submit' : 'disabled'}/>
            </div>
          </form>
          <div>
            <p>{t("login.question")} <Link to='/page/register'>{t("login.signup")}</Link> </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login