import React from 'react'
import { FaEnvelope, FaMapMarker, FaMobileAlt } from 'react-icons/fa';
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';
import './../../model/cv/model4.css'

const Model4 = ({data}) => {

    const [t, i18n] = useTranslation('global');

    return (
        <div className="l-main bd-container" id='min_size'>
            <div className="resume" id="area-cv">
                <div className="resume__left">
                    <section className="home" id="home">

                        <div className="home_containter section bd-grid">

                            {
                                (data?.photo || data?.info?.firstname || data?.info?.name) &&
                                <div className="home_data bd-grid">
                                    {data?.photo && <img src={data?.photo} alt="profile_pic" className="home_img center"/>}
                                
                                    <h1 className="home_title"> {(data?.info?.firstname) && `${data?.info?.firstname}  `} <b>{(data?.info?.name) && data?.info?.name }</b></h1>
                               
                                </div>
                            }


                            {
                                (data?.info?.postal_address || data?.info?.email ||  data?.info?.tel) &&

                                <div className="home_address bd-grid">
                                    {
                                       data?.info?.postal_address && 
                                       <span className="home_information">
                                            <FaMapMarker/>
                                            {(data?.info?.postal_address)}
                                        </span>
                                    }

                                    {
                                        data?.info?.email &&
                                        <span className="home_information">
                                            <FaEnvelope/>
                                            <a href={`mailto:${data?.info?.email}`} style={{color : 'black', paddingLeft:"2px"}}> {data?.info?.email}</a>
                                        </span>
                                    }

                                    {
                                        data?.info?.tel &&
                                        <span className="home_information">
                                            <FaMobileAlt/>
                                            {(data?.info?.tel)}
                                        </span>
                                    }
                                </div>

                            }

                        </div>
                        
                       
                    </section>          
                    
                   
                   {
                    (data?.profile) &&
                    <section className="profile section" id="profile">
                        <h2 className="section-title">{t('cv.profile')}</h2>
                        <p className="profile_description">
                            {(data?.profile)}
                        </p>
                    </section>
                   }
                    
                    
                    {
                        (data?.formations?.length > 0) &&
                        <section className="education section" id="education">
                            <h2 className="section-title">{t('cv.education')}</h2>

                            {
                                <div className="education_container bd-grid">
                                    {data?.formations?.map((formation, i) => (
                                        <div className="education_content" key={i}>
                                            <div className="education_time">
                                                <span className="education_rounder"></span>
                                                <span className="education_line"></span>
                                            </div>
                                            <div className="education_data bd-grid">
                                                <h3 className="education_title">{formation?.formation} ({formation?.etablissement} {formation?.city})</h3>
                                                <span className="education_studies">{formation?.description}</span>
                                                <span className="education_year">{dayjs(formation?.begin).format('DD/MM/YYYY')} au {dayjs(formation?.end).format('DD/MM/YYYY')}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div> 
                            }

                        </section>
                    }

                    
                    {
                        (data?.skill?.length > 0) &&
                        <section className="skills section" id="skills">
                            <h2 className="section-title">{t('cv.skill')}</h2>

                            <div className="skills_content bd-grid">

                            {
                                <ul className="skills_data">
                                    {data?.skill?.map((skill, i) => (
                                        <li key={i} className="skills_name">
                                            <span className="skills_circle"></span> {skill?.skill} : {`${(parseInt(skill?.level) * 30)}%`}
                                        </li>
                                    ))}
                                </ul> 
                            }
                            </div>
                        </section>
                    }

                </div>
                

                <div className="resume__right">

                    {
                        (data?.experiences?.length > 0) &&
                        <section className="experience section" id="experience">
                            <h2 className="section-title">{t('cv.experience')}</h2>

                            {
                                <div className="experience_container bd-grid">
                                    {data?.experiences?.map((experience, i) => (
                                        <div key={i} div className="experience_content">
                                            <div className="experience_time">
                                                <span className="experience_rounder"></span>
                                                <span className="experience_line"></span>
                                            </div>
                                            <div className="experience_data bd-grid">
                                                <h3 className="experience_title">{experience?.job} ({experience?.etablissement} {experience?.city})</h3>
                                                <span className="experience_company">{dayjs(experience?.begin).format('DD/MM/YYYY')} au {dayjs(experience?.end).format('DD/MM/YYYY')}</span>
                                                <p className="experience_description">
                                                    {experience?.description}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div> 
                            }

                        </section>
                    }



                    {
                        (data?.langages?.length > 0) &&
                        <section className="languages section">
                            <h2 className="section-title">{t('cv.language')}</h2>

                            <div className="languages_container">
                            {
                                    <ul className="languages_content bd-grid">
                                        {data?.langages?.map((langage, i) => (
                                        <li key={i} className="languages_name">
                                            <div className="data">
                                                <p className="semi-bold"> <span className="languages_circle"></span> {langage?.langage}</p>
                                                <p>Très bien</p>
                                            </div>
                                        </li>
                                        ))}
                                    </ul> 
                            }

                            </div>

                        </section>
                    }

                    
                    {
                        (data?.hobby?.length > 0) &&
                        <section className="interests section">
                            <h2 className="section-title">{t('cv.hobby')}</h2>
                            {
                                <div className="interests_container bd-grid">
                                    {data?.hobby?.map((hobby, i) => (
                                        ( 
                                        <div className="interests_content" key={i}>
                                            <span className="interests_name">{hobby}</span>
                                        </div>
                                        )
                                    ))}
                                </div> 
                            }  
                        </section>
                    }

                </div>
            </div>        
        </div> 
    )
}


Model4.displayName = 'model4';

export default Model4;