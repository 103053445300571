import React from 'react'
import { FaEnvelope, FaMapSigns, FaMobileAlt } from 'react-icons/fa';
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';
import './../../model/cv/model5.css'

const Model5 = ({data}) => {

    const [t, i18n] = useTranslation('global');

    return (
        <div className="resume_model_cendrine" id='min_size'>
            <div className="resume_top">
                {
                    data?.photo &&
                    <div className="resume_profile">
                        <img src={data?.photo} alt="profile_pic"/>
                    </div>
                }


                {/* La section Information personnelle */}
                <div className="resume_info">
                        {
                            (data?.info?.name || data?.info?.firstname) &&
                            <div className="title">
                                {data?.info?.name && <span className="bold">{`${data?.info?.name} `}</span>}
                                {data?.info?.firstname && <span className="bold">{data?.info?.firstname}</span>}
                            </div>

                        }

                        <ul>
                            {
                                data?.info?.tel &&
                                <li>
                                    <div className="data"> <FaMobileAlt/> {data?.info?.tel}</div>
                                </li>
                            }
                            {
                                data?.info?.email &&
                                <li>
                                    <div className="data"> <FaEnvelope/> <a href={`mailto:${data?.info?.email}`} style={{color : 'white'}}>{data?.info?.email}</a></div>          
                                </li>
                            }
                            {
                                data?.info?.postal_address &&
                                 <li>
                                    <div className="data"> <FaMapSigns/> {data?.info?.postal_address}</div>
                                </li>
                            }
                        </ul>
                    </div>
                </div>


            <div className="resume_bottom">
                <div className="resume_bottom_left">

                    {/* La section Profile */}
                    {
                        data?.profile &&
                            <div className="resume_item resume_about">
                            <div className="title">
                                <p className="bold">{t('cv.profile')}</p>
                            </div>
                            <p> {data?.profile} </p>
                        </div>
                    }


                    
                    {/* La section experience professionnelle */}
                    {
                        data?.experiences?.length > 0 &&
                        <div className="resume_item">
                            <div className="title">
                                <p className="bold">{t('cv.experience')}</p>
                            </div>

                            {
                                    <ul>
                                        {data?.experiences?.map((experience, i) => (
                                            <li key={i}>
                                                <div className="date">{dayjs(experience?.begin).format('DD/MM/YYYY')} au {dayjs(experience?.end).format('DD/MM/YYYY')}</div> 
                                                <div className="info">
                                                    <p className="semi-bold">{experience?.job} ({experience?.etablissement} {experience?.city})</p> 
                                                    <p>{experience?.description}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                            }

                        </div>
                    }


                    {/* La section Formation */}
                    {
                        (data?.formations?.length > 0) &&
                        <div className="resume_item">
                            <div className="title">
                                <p className="bold">{t('cv.education')}</p>
                            </div>

                            {
                                    <ul>
                                        {data?.formations?.map((formation, i) => (
                                            <li key={i}>
                                                <div className="date">{dayjs(formation?.begin).format('DD/MM/YYYY')} au {dayjs(formation?.end).format('DD/MM/YYYY')}</div> 
                                                <div className="info">
                                                    <p className="semi-bold">{formation?.formation} ({formation?.etablissement} {formation?.city})</p> 
                                                    <p>{formation?.description}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul> 
                            }

                        </div>
                    }


                </div>

                

                <div className="resume_bottom_right">
                    {/* La section Competence */}
                    {
                        (data?.skill?.length > 0) &&
                        <div className="resume_item">
                            <div className="title">
                                <p className="bold">{t('cv.skill')}</p>
                            </div>


                            {
                                <ul>
                                    {data?.skill?.map((skill, i) => (
                                        <li key={i}>
                                            <div className="skill_name">{skill?.skill} : <span>{skill?.level}/3</span></div>
                                        </li>
                                    ))}
                                </ul> 
                            }
                        </div>
                    }



                    {/* La section Langues */}
                    {
                        (data?.langages?.length > 0) &&
                        <div className="resume_item">
                            <div className="title">
                                <p className="bold">{t('cv.language')}</p>
                            </div>


                            {
                                    <ul>
                                        {data?.langages?.map((langage, i) => (
                                        <li key={i}>
                                            <div className="data">
                                                <p className="semi-bold">{langage?.langage}</p>
                                                <p>Très bien</p>
                                            </div>
                                        </li>
                                        ))}
                                    </ul> 
                            }
                        </div>
                    }


                    {/* La section Centre d'intéret */}
                    {
                        (data?.hobby?.length > 0) &&
                        <div className="resume_item">
                            <div className="title">
                                <p className="bold">{t('cv.hobby')}</p>
                            </div>

                            {
                                    <ul className='m5-hobby'>
                                        {data?.hobby?.map((hobby, i) => (
                                            (<li key={i}>{hobby}</li>)
                                        ))}
                                    </ul> 
                            }  
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

Model5.displayName = 'model5';

export default Model5;