import React, { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import {FaBars, FaUser, FaUserAltSlash} from 'react-icons/fa'
import './../styles/header.css'
import UserContext from '../context/UserContext'
import Tag from '../helpers/Tag'
import { useTranslation } from "react-i18next";
import fasocv from './../images/fasocv.png';
import profile from './../images/profile.png';

function Header() {

    const isAuth = useContext(UserContext);
    const [t, i18n] = useTranslation('global');
    const language = ['fr','en'];



    const handleLang = (event)=>{
        if (language.includes(event.target.value)) {
            i18n.changeLanguage(event.target.value);
        }
        
    }


    return (
        <header className='header'>
            <nav className='nav'>
                <label htmlFor='menu_icon' id='menu_label'>
                    <FaBars size={32} />
                </label>
                <input type='checkbox' id='menu_icon' />

                <span id='li-logo' >
                        <Link to='/' >
                            <img className='li-home' src={fasocv} alt='icon de la plateforme' width='120px' height='35px'/>
                        </Link>
                </span>
                <ul>
                    <li>
                        <NavLink to='/cv-model' className={nav => (nav.isActive ? 'active' : '')}>{t('header.link_cv')}</NavLink>
                    </li>
                    <li>
                        <NavLink to='/ldm-model' className={nav => (nav.isActive ? 'active' : '')}>{t('header.link_ldm')}</NavLink>
                    </li>
                    <li>
                        <select className='tag-container' onChange={handleLang} >
                            {
                                language.map(tag => (
                                    <option key={tag} value={tag.toString()}>{ tag.toString() === 'en' ? Tag('us') : Tag(tag)}</option>
                                ))
                            }
                        </select>
                    </li>
                </ul>

                <div className='wrapper_profile'>
                    {isAuth?.isAuth ? <Link className='profile-icon' to='/mon/profile'><img src={profile} width='50px' height='50px'/></Link> : <Link className='profile-icon'  to='/page/login'><img src={profile} width='50px' height='50px'/></Link>}
                </div>
            </nav>
        </header>
    )
}

export default Header