import React from 'react';
import './../../model/cv/model6.css';
import { FaEnvelope, FaMapSigns, FaPhone } from 'react-icons/fa';
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';

const Model6 = ({data}) => {
    const [t, i18n] = useTranslation('global');

    return (
        <div className='model6-container'>
            <div className="left_Side">
                <div className="profileText">
                    {
                        data?.photo &&
                        <div className="imgBx">
                            <img src={data?.photo} alt="profile_pic"/>
                        </div>
                    }
                    {(data?.info?.name || data?.info?.firstname) && <h2>{data?.info?.name} {data?.info?.firstname} </h2> }
                    
                </div>

                { (data?.info?.tel || data?.info?.email || data?.info?.postal_address) && <div className="contactInfo">
                    <h3 className="title">Contact Info</h3>
                    <ul>
                        {
                            (data?.info?.tel) && 
                            <li>
                                <span className="icon"><FaPhone/></span>
                                <span className="text">{data?.info?.tel}</span>
                            </li>
                        }

                        {
                            (data?.info?.email) && 
                            <li>
                                <span className="icon"><FaEnvelope/></span>
                                <a href={`mailto:${data?.info?.email}`} className="text" style={{color : 'white'}}>{data?.info?.email}</a>
                            </li>
                        }

                        {
                            (data?.info?.postal_address) && 
                            <li>
                                <span className="icon"><FaMapSigns/></span>
                                <span className="text">{data?.info?.postal_address}</span>
                            </li>
                        }
                    </ul>
                </div>}

            {
                (data?.formations?.length > 0) &&
                <div className="contactInfo education">
                    <h3 className="title">{t('cv.education')}</h3>
                    <ul>
                        {data?.formations?.map((formation, i) => (
                                <li key={i}>
                                    <h5>{dayjs(formation?.begin).format('DD/MM/YYYY')} au {dayjs(formation?.end).format('DD/MM/YYYY')}</h5> 
                                    <h4>{formation?.formation} ({formation?.etablissement} {formation?.city})</h4>
                                    <h4 >{formation?.description}</h4>
                                </li>
                        ))}
                    </ul>
                </div>
            }


            {
                (data?.langages?.length > 0) &&

                <div className="contactInfo language">
                    <h3 className="title">{t('cv.language')}</h3>
                    <ul>
                        {data?.langages?.map((langage, i) => (
                            <li key={i}>
                                <span className="text">{langage?.langage}</span>
                                <span className="percent"></span>
                                <div style={{width : `${parseInt(langage?.level) * 30}%`}} ></div>
                            </li>
                        ))}
                    </ul>
                </div>
                
            }
            </div>

            <div className='right_Side'>

                {
                    (data?.profile) && 
                    <div className="about">
                        <h2 className="title2">{t('cv.profile')}</h2>
                        <p>{data?.profile} </p>
                    </div>
                }



                {
                    (data?.experiences?.length > 0) &&

                    <div className="about">
                        <h2 className="title2">{t('cv.experience')}</h2>
                        {data?.experiences?.map((experience, i) => (
                            <div className="box" key={i}>
                                <div className="year_company">
                                    <h5>{dayjs(experience?.begin).format('DD/MM/YYYY')} - {dayjs(experience?.end).format('DD/MM/YYYY')}</h5>
                                    <h5>{experience?.etablissement}</h5>
                                </div>
                                <div className="text">
                                    <h4>{experience?.job}</h4>
                                    <p>{experience?.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>    
                }


                
                {
                    (data?.skill?.length > 0) &&
                    <div className="about skills">
                        <h2 className="title2">{(data?.skill?.length > 0) && t('cv.skill')}</h2>
            


                        {data?.skill?.map((skill, i) => (
                            <div className="box" key={i}>
                                <h4>{skill?.skill}</h4>
                                <div className="percent">
                                            <div style={{width : `${(parseInt(skill?.level) * 30)}%`}}></div>
                                </div>
                            </div>  
                        ))}
                    </div>
                }



                {
                    (data?.hobby?.length > 0) && 
                    <div class="About interest">
                        <h2 class="title2">{t('cv.hobby')}</h2>
                        <ul>
                            {data?.hobby?.map((hobby, i) => (
                                    (<li key={i}>{hobby}</li>)
                            ))}
                        </ul>
                    </div>
                }

            </div>
        </div>
    );
};

Model6.displayName = 'Model6';

export default Model6;