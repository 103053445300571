function CountWord(chaine) {
    chaine = chaine?.trim();
    
    if (chaine === '') {
      return 0;
    }

    if (chaine === undefined) {
      return 0;
    }
    
    const mots = chaine?.split(/\s+/);
    
    return mots?.length;
}

export default CountWord;