import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

function Skill({inputSkills, setInputSkills, handleAddInput, listSkill, setListSkill}) {

    const [skill, setSkill] = useState({skill : "", level : 1});
    const [showInput, setShowInput] = useState(false);
    const [t, i18n] = useTranslation('global');


    useEffect(()=>{
        let val = listSkill?.map((el,index) =>({id : index}));
        setInputSkills(val);
    }, [showInput])

    const handleSkill = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
    
        setSkill(values => ({...values, [name] : value}));
    }


    const handleClick = (indexInputSkill) =>{
        //let level = skill?.level ? skill?.level : 2;

        if(skill?.skill && skill?.level){
            if (listSkill[indexInputSkill]) {
                const newListSkill = [...listSkill];
                newListSkill.splice(indexInputSkill, 1, skill);
                setListSkill(newListSkill);

                let data = localStorage.getItem('cv_data');
                data = JSON.parse(data);
                data?.listSkill?.splice(indexInputSkill, 1, skill);
                localStorage.setItem('cv_data', JSON.stringify(data))
                setSkill({});
                
            } else {
                setListSkill((oldVal) => [...oldVal, skill]);
                let data = localStorage.getItem('cv_data');
                data = JSON.parse(data);
                data?.listSkill?.push(skill)
                localStorage.setItem('cv_data', JSON.stringify(data))
                setSkill({});
            }
        }
        else{
            
        }
    }

    return (
        <section className='area'>
            <h3 className='title mix'>
                <span>{t('cv.skill')}</span>
                <div>
                    <button className='add' onClick={()=>{handleAddInput(setInputSkills); setShowInput(true)}}>+</button>
                    <button className='add' onClick={()=>{setShowInput(!showInput)}}> {showInput ? 'v' : '^'}</button>
                </div>
            </h3>
            {
                showInput &&
                <div>
                    {inputSkills.map((field, index) => (
                        <div className='div_wrapper_input' key={index}>
                            <p>
                                <label htmlFor="skill">{t('cv.n_skill')} </label>
                                <span className='require'>*</span>
                            </p>
                            <input className='body_textarea' id='skill' name='skill' onChange={handleSkill} defaultValue={listSkill[index]?.skill} />
                            <p>
                                <label htmlFor="level">{t('cv.level')} : </label> 
                                <span className='require'>*</span>
                            </p>
                            <p><input type="range" id="level" name="level" min="1" max="3" onChange={handleSkill} defaultValue={listSkill[index]?.level}  /></p>
                            <div className='container_btn_manager'>
                                <button onClick={() => {
                                    setInputSkills(inputSkills.filter((ele, i) => ele?.id !== field?.id));
                                    listSkill?.splice(index, 1);
                                    setListSkill(listSkill);

                                    let data = localStorage.getItem('cv_data');
                                    data = JSON.parse(data);
                                    data?.listSkill?.splice(index, 1);
                                    localStorage.setItem('cv_data', JSON.stringify(data))
                                }}>{t('cv.delete')}</button>
                                <button onClick={()=>{handleClick(index)}}>{t('login.validate')}</button>
                            </div>
                        </div>
                    )).reverse()}
                </div>
            }
        </section>
    )
}

export default Skill