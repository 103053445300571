import React, { useEffect, useState }  from 'react'
import GetData from '../helpers/GetData'
import {FaTrash} from 'react-icons/fa';
import DeleteDoc from '../helpers/DeleteDoc';
import './../styles/list.css'
import Ldm from '../components/Ldm';

function AdminLdm() {

  const [ldm, setLdm] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(()=>{GetData('/all_ldm', setLdm)}, [])

  return (
    <div className='wrapper_all'>

      <div className='wrapper_list'>
        
          <div className='list'>
            <h2 className='title_list'>Tous les Ldm({ldm.length})</h2>
            {ldm.map((ldm, i) => (
              <div onClick={()=>{setIndex(i)}} key={ldm?._id}>
                <p>{ldm?._id}</p>
                <span onClick={()=>{DeleteDoc(`/delete_ldm/${ldm?._id}`,setLdm, ldm?._id)}}>
                  <FaTrash/>
                </span>
              </div>
            ))}
          </div>
      
          <div className='wrapper_table'>
                {
                  
                  ldm.length > 0 &&
                  <Ldm info={ldm[index]?.information} object={ldm[index]?.object} content={ldm[index]?.content} />
                }
          </div>
      </div>
    </div>
  )
}

export default AdminLdm