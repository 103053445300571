import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

function Hobby({inputHobby, setInputHobby, handleAddInput, listHobby, setListHobby}) {

    const [showInput, setShowInput] = useState(false);
    const [hobby, setHobby] = useState('');
    const [t, i18n] = useTranslation('global');

    useEffect(()=>{
        let val = listHobby?.map((el,index) =>({id : index}));
        setInputHobby(val);
    }, [showInput])


    const handleClick = (indexInputHobby) =>{
        //on verifie si une information existe dans le champ hobby
        if(hobby){
            //ici on verifie si le champ valider est un nouveau ou doit etre modifier
            if (listHobby[indexInputHobby]) {
                const newListHobby = [...listHobby];
                newListHobby.splice(indexInputHobby, 1, hobby);
                setListHobby(newListHobby);
                
                let data = localStorage.getItem('cv_data');
                data = JSON.parse(data);
                data?.listHobby?.splice(indexInputHobby, 1, hobby);
                localStorage.setItem('cv_data', JSON.stringify(data));
            } else{
                setListHobby((oldVal) => [...oldVal, hobby]);
                let data = localStorage.getItem('cv_data');
                data = JSON.parse(data);
                data?.listHobby?.push(hobby);
                localStorage.setItem('cv_data', JSON.stringify(data));
            }

        }
        else{
            
        }
    }
    
    return (
        <section className='area'>
            <h3 className='title mix'>
                <span>{t('cv.hobby')}</span>
                <div>
                    <button className='add' onClick={()=>{handleAddInput(setInputHobby); setShowInput(true)}} >+</button>
                    <button className='add' onClick={()=>{setShowInput(!showInput)}}> {showInput ? 'v' : '^'}</button>
                </div>
                
            </h3>
            {
                showInput &&
                <div>
                    {inputHobby.map((field, index) => (
                        <div className='div_wrapper_input' key={index}>
                            <p>{t('cv.hobby')}</p>
                            <input className='body_textarea' onChange={(e)=>{setHobby(e.target.value)}} defaultValue={listHobby[index]} />

                            <div className='container_btn_manager'>
                                <button onClick={() => {
                                    setInputHobby(inputHobby.filter((ele, i) => ele?.id !== field?.id));
                                    listHobby?.splice(index, 1);
                                    setListHobby(listHobby);

                                    let data = localStorage.getItem('cv_data');
                                    data = JSON.parse(data);
                                    data?.listHobby?.splice(index, 1);
                                    localStorage.setItem('cv_data', JSON.stringify(data))
                                }}
                                >{t('cv.delete')}</button>

                                <button onClick={()=>{handleClick(index)}}>{t('login.validate')}</button>
                            </div>
                        </div>
                    )).reverse()}
                </div>
            }
        </section>
    )
}

export default Hobby