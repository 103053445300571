import React, { useState } from 'react'
import './../styles/login.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';

function Register() {

    const [data, setData] = useState({name : '', firstname : '', email : '', password : '', repeatPassword : ''});
    const [error, setError] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [step, setStep] = useState(0);
    const [t, i18n] = useTranslation('global');
    const redirect = useNavigate();
    

    const hanbleData = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setData(values => ({...values, [name] : value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!data?.name) {
            setError(t("login.nameReq"))
        }
        else if(!data?.firstname) {
            setError(t("login.firstnameReq"))
        }
        else if(!data?.email) {
            setError(t("login.emailReq"))
        }
        else if(!data?.password) {
            setError(t("login.pwdReq"))
        }
        else if(!data?.repeatPassword) {
            setError(t("login.rpwdReq"))
        }
        else if(data?.repeatPassword != data?.password) {
            setError(t("login.iden"))
        }
        else {
            setSubmitDisabled(true);
            setError('');
            try {
                const res = await axios.post('/register', data);

                Swal.fire({  
                    title: t("login.congratulate"), 
                    text: res.data.message,
                    icon: 'success'
                }); 
                
                redirect('/page/login')
            } 
            catch (error) {
                if (error?.response) {
                    setError(error?.response.data.message);
                }
                else{
                    Swal.fire({  
                        text: t("login.error"),
                        icon: 'error'
                    }); 
                }
                setSubmitDisabled(false);
            }
        }
    }


    const renderInput = () => {

        if (step === 0) {
            return (
                <>
                    <p>{t("login.name")}</p>
                    <div className='div_input'>
                        <input type='text' name='name' onChange={hanbleData} value={data?.name}/>
                    </div>
                </>
            )
        }

        if (step === 1) {
            return (
                <>
                    <p>{t("login.firstname")}</p>
                    <div className='div_input'>
                        <input type='text' name='firstname' onChange={hanbleData} value={data?.firstname}/>
                    </div>
                </>
            )
        }

        if (step === 2) {
            return (
                <>
                    <p>{t("login.email")}</p>
                    <div className='div_input'>
                        <input type='email' name='email' onChange={hanbleData} value={data?.email}/>
                    </div>
                </>
            )
        }

        if (step === 3) {
            return (
                <>
                    <p>{t("login.password")}</p>
                    <div className='div_input'>
                        <input type='password' name='password' onChange={hanbleData} value={data?.password}/>
                    </div>
                </>
            )
        }

        if (step === 4) {
            return (
                <>
                    <p>{t("login.rpwdReq")}</p>
                    <div className='div_input'>
                        <input type='password' name='repeatPassword' onChange={hanbleData} value={data?.repeatPassword}/>
                    </div>
                </>
            )
        }
    }

    return (
        <div className='login'>
            <div className='container_img'>

            </div>
            <div className='container_div_form'>
                <div className='container_form'>
                    <h1>{t("login.signup")}</h1>
                    <i className='error'>{error}</i>
                    <form className='form'>

                        {renderInput()}
                        <div className='div_input'>
                            <button 
                                className='btn_form'
                                type='button' onClick={()=>{if(step > 0) setStep(step - 1)}}>{t("login.prev")}</button>
                            
                            { step == 4 ? <button type='button' className={!submitDisabled ? 'btn_form' : 'disabled'} disabled={submitDisabled} onClick={handleSubmit}>{!submitDisabled ? t("login.signup") : t("login.processing")}</button> :                             <button 
                                className='btn_form' 
                                type='button'
                                onClick={()=>{if(step < 4) setStep(step + 1)}}>{t("login.next")}</button>
                            }

                        </div>
                    </form>
                    
                    <div>
                        <p>{t("login.question1")} <Link to='/page/login'>{t("login.submit")}</Link> </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Register