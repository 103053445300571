import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import CountWord from '../../helpers/CountWord';
import Swal from 'sweetalert2';


/**
 * 
 * @param 
 * inputFormations : c'est le tableau qui sera parcouri pour afficher les champ qui permettent l'ajout Formations
 * setInputFormations: permettait d'ajouter ou de retirer des champs au niveau de Formations
 * handleAddInput : permet de modifier setInputFormations en ajoutant de nouvel element
 * listFormations: cest un tableau qui present la liste des experience valider qui seront passe en props au composant model de cv
 * setListFormations : permet de d'ajouter ou retirer des experience dans la liste des Formations de notre CV
 * @returns 
 */
function Formation({inputFormations, setInputFormations, handleAddInput, listFormation, setListFormation}) {

    //permet de recuperer la formation courant saisi
    const [formation, setFormation] = useState({
        formation : "",
        description : "",
        etablissement : "",
        city : "",
        begin : "",
        end : "",
    });
    //permettra d'afficher ou de cacher les champs generer
    const [showInput, setShowInput] = useState(false);

    const [t, i18n] = useTranslation('global');



    //pendant le montage et la modification du composant on met nos deux tableau a la meme longueur
    // pour fiare de sorte que la liste des experiences valider soit egal a la liste des champs generer
    useEffect(()=>{
        let val = listFormation?.map((el,index) =>({id : index}));
        setInputFormations(val);
    }, [showInput])


    //permet la recuperation des formations saisis
    const handleFormation = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        setFormation(values => ({...values, [name] : value}));

    }



    //cette fonction verifie si tous les info concernant la formation est fourni met a jour la listes des formations valide
    //met aussi a jour le localstorage
    // et vide le state contenant la formation
    const handleClick = (indexInputFormation) =>{

        //si on a un element dans la liste des formation sur le cv on doit le modifier
        if (listFormation[indexInputFormation]) {

            for (const key in formation) {
                if (formation[key]?.trim() === '' || formation[key] === undefined || formation[key] === null ) {
                    formation[key] = listFormation[indexInputFormation][key]
                }
            }

            if(formation?.formation && formation?.etablissement && formation?.city && formation?.begin && formation?.end ){
            
                // ceci je verifie si la description ne depasse pas 100 mots
                if (CountWord(formation?.description) <= 60) {
                    
                    //je cree un nouveau tableau dans lequel je remplace la formation modifier par ses nouveaux valeur
                    const newListFormation = [...listFormation];
                    newListFormation.splice(indexInputFormation, 1, formation);
                    //cette nouvelle liste remplacera la liste des formation dans le cv
                    setListFormation(newListFormation);
                            
                    //ces instructions recupere les info actuelle du cv sur le local storage
                    //et le met a jour en ajoutant la formation actuelle
                    let data = localStorage.getItem('cv_data');
                    data = JSON.parse(data);
                    data?.listFormation?.splice(indexInputFormation, 1, formation);
                    localStorage.setItem('cv_data', JSON.stringify(data))
                    setFormation({
                        formation : "",
                        description : "",
                        etablissement : "",
                        city : "",
                        begin : "",
                        end : "",
                    }); 
                               
                } else {
                    Swal.fire({title: '', text: t('cv.length_alert'), icon: 'error'})
                }
            }

        }
        //sinon on doit l'ajouter dans la liste des formations dans le cv 
        else {
            if(formation?.formation && formation?.etablissement && formation?.city && formation?.begin && formation?.end ){
            
                // ceci je verifie si la description ne depasse pas 100 mots
                if (CountWord(formation?.description) <= 60) {
                    
                    //cette ajoute la formation actuelle sur la partie
                    setListFormation((oldVal) => [...oldVal, formation]);
                            
                    //ces instructions recupere les info actuelle du cv sur le local storage
                    //et le met a jour en ajoutant la formation actuelle
                    let data = localStorage.getItem('cv_data');
                    data = JSON.parse(data);
                    data?.listFormation?.push(formation)
                    localStorage.setItem('cv_data', JSON.stringify(data))
                    setFormation({
                        formation : "",
                        description : "",
                        etablissement : "",
                        city : "",
                        begin : "",
                        end : "",
                    }); 
                               
                } else {
                    Swal.fire({title: '', text: t('cv.length_alert'), icon: 'error'})
                }
            }
        }
    }


    return (
        <section className='area'>
            <h3 className='title mix'>
                <span>{t('cv.education')} (5 diplomes Max)</span>
                <div>
                    <button className='add' onClick={() => {
                        inputFormations?.length < 5 ? handleAddInput(setInputFormations) : Swal.fire({icon : 'info', text : t('cv.length_educ') });
                        setShowInput(true)}}
                    >+</button>
                    <button className='add' onClick={()=>{setShowInput(!showInput)}}> {showInput ? 'v' : '^'}</button>             
                </div>
            </h3>

            {
                showInput &&

                <div >
                    {inputFormations.map((field, index) => (
                        <div className='container_div_wrapper' key={index}>
                            <div className='div_all div_padding'>
                                <p>
                                    <label htmlFor='formation'>{t('cv.form')} </label>
                                    <span className='require'>*</span>
                                </p>
                                <input id='formation' type='text' name='formation' onChange={handleFormation} defaultValue={listFormation[index]?.formation} />
                            </div>

                            <div className='div_padding'>
                                <p>
                                    <label htmlFor='etablissement'>{t('cv.establishment')} </label>
                                    <span className='require'>*</span>
                                </p>
                                <input id='etablissement' name='etablissement' onChange={handleFormation} defaultValue={listFormation[index]?.etablissement} />
                            </div>

                            <div className='div_padding'>
                                <p>
                                    <label htmlFor='ville'>{t('cv.city')} </label>
                                    <span className='require'>*</span>
                                </p>
                                <input id='ville' type='text' name='city' onChange={handleFormation} defaultValue={listFormation[index]?.city} />
                            </div>

                            <div className='div_padding'>
                                <p>
                                    <label htmlFor='begin' >{t('cv.begin')} </label>
                                    <span className='require'>*</span>
                                </p>
                                <input id='begin' type='date' name='begin' onChange={handleFormation} defaultValue={listFormation[index]?.begin}/>
                            </div>

                            <div className='div_padding'>
                                <p>
                                    <label htmlFor='end' >{t('cv.end')} </label>
                                    <span className='require'>*</span>
                                </p>
                                <input id='end' type='date' name='end' onChange={handleFormation} defaultValue={listFormation[index]?.end} />
                            </div>

                            <div className='div_all div_padding'>
                                <p>
                                    <label htmlFor='' >{t('cv.description')} ({t('cv.max')})</label>
                                </p>
                                <textarea rows={8} name='description' onChange={handleFormation} defaultValue={listFormation[index]?.description} ></textarea>
                            </div>

                            <div className='container_btn_manager'>
                                    <button onClick={() => {
                                        setInputFormations(inputFormations.filter((ele, i) => ele?.id !== field?.id));
                                        listFormation?.splice(index, 1);
                                        setListFormation(listFormation);
                                        
                                        let data = localStorage.getItem('cv_data');
                                        data = JSON.parse(data);
                                        data?.listFormation?.splice(index, 1);
                                        localStorage.setItem('cv_data', JSON.stringify(data))
                                    }}>{t('cv.delete')}</button>
                                    <button onClick={()=>{handleClick(index)}}>{t('login.validate')}</button>
                                    
                            </div>
                            
                        </div>
                    )).reverse()}
                </div>
            }
        </section>
    )
}

export default Formation