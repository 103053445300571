import React from 'react'
import { FaEnvelope, FaMapMarker, FaMobileAlt } from 'react-icons/fa';
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';
import './../../model/cv/model3.css'

const Model3 = ({data}) => {

    const [t, i18n] = useTranslation('global');

    return (
            <div className="model3_container" id='min_size'>
                <div className="left_Side">

                    <div className="profileText">
                        {
                            data?.photo &&
                            <div className="imgBx">
                                <img className="photo" src={data?.photo} alt="profile_pic"/>
                            </div>
                        }

                        <br/>
                        {
                            (data?.info?.name || data?.info?.firstname) &&
                            <h2>
                                {data?.info?.name && <span >{(data?.info?.name)}</span>}
                                {data?.info?.firstname && <span>{(data?.info?.firstname)}</span>}
                            </h2>
                        }

                    </div>

                    {
                        (data?.info?.tel || data?.info?.email || data?.info?.postal_address ) &&
                        <div className="contactInfo">
                            <h3 className="title">Contact</h3>
                            <ul>
                                {
                                    data?.info?.tel && 
                                    <li>
                                        <span className="icon"><FaMobileAlt/></span>
                                        <span className="text">{(data?.info?.tel)}</span>
                                    </li>
                                }
                                {
                                    data?.info?.email &&
                                    <li>
                                        <span className="icon"><FaEnvelope/></span>
                                        
                                        <span className="text">
                                        <a href={`mailto:${data?.info?.email}`} style={{color : '#c5c5c5'}}>{data?.info?.email}</a>
                                        </span>
                                    </li>
                                }
                                {
                                    data?.info?.postal_address &&
                                    <li>
                                        <span className="icon"><FaMapMarker/></span>
                                        <span className="text">{(data?.info?.postal_address)}</span>
                                    </li>
                                }
                            </ul>
                        </div>
                    }


                    {
                        (data?.formations?.length > 0) &&
                        <div className="contactInfo education">
                            <h3 className="title">{t('cv.education')}</h3>

                            {
                                <ul>
                                    {data?.formations?.map((formation, i) => (
                                        <li key={i}>
                                            <h5>{dayjs(formation?.begin).format('DD/MM/YYYY')} - {dayjs(formation?.end).format('DD/MM/YYYY')}</h5> 
                                            <h4>{formation?.formation} ({formation?.etablissement} {formation?.city})</h4> 
                                            <p className='desc-formation'>{formation?.description}</p>
                                        </li>
                                    ))}
                                </ul> 
                            }
                        </div>
                    }


                    {
                        data?.langages?.length > 0 &&
                        <div className="contactInfo language">
                            <h3 className="title">{t('cv.language')}</h3>
                            {
                                    <>
                                        {data?.langages?.map((langage, i) => (
                                        <ul key={i}>
                                            <li>
                                                <span className="text">{langage?.langage}</span>
                                                <span className="percent">
                                                    <div style={{width : `${(parseInt(langage?.level) * 30)}%`}}></div>
                                                </span>
                                            </li>
                                        </ul>
                                        ))}
                                    </> 
                            }
                        </div>
                    }

                </div>


                <div className="right_Side">
                    {
                        (data?.profile) && 
                        <div className="about">
                            <h2 className="title2">{t('cv.profile')}</h2>
                            <p>
                                <p> {data?.profile }</p>   
                            </p>
                        </div>
                    }


                    {
                        data?.experiences?.length > 0 &&
                        <div className="about">
                            <h2 className="title2">{t('cv.experience')}</h2>

                            {
                                <>
                                    {data?.experiences?.map((experience, i) => (
                                        <div className="box" key={i}>
                                            <div className="year_company">
                                            <h5>{dayjs(experience?.begin).format('DD/MM/YYYY')} au {dayjs(experience?.end).format('DD/MM/YYYY')}</h5> 
                                            <h5>{experience?.etablissement} {experience?.city}</h5>
                                            </div> 
                                            <div className="text">
                                                <h4>{experience?.job}</h4> 
                                                <p>{experience?.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </> 
                            }

                        </div>
                    }


                    {
                        (data?.skill?.length > 0) &&
                        <div className="about skills">
                        <h2 className="title2">{t('cv.skill')}</h2>

                        {
                            <>
                                {data?.skill?.map((skill, i) => (
                                    <div className="box" key={i}>
                                        <h4>{skill?.skill}</h4>
                                        <div className="percent">
                                            <div style={{width : `${(parseInt(skill?.level) * 30)}%`}}></div>
                                        </div>
                                    </div>
                                ))}
                            </> 
                        }

                        </div>
                    }


                    {
                       (data?.hobby?.length > 0) && 
                       <div className="about interest">
                            <h2 className="title2">{t('cv.hobby')}</h2>
                            {
                                <ul>
                                    {data?.hobby?.map((hobby, i) => (
                                            (<li key={i}>{hobby}</li>)
                                    ))}
                                </ul> 
                            }  
                        </div>
                    }


                </div>
            </div>
    )
}

Model3.displayName = 'model3'

export default Model3